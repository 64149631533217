body {
  .retail {
    @import './themes/default';
    @import './themes/kiwibank';
    @import './themes/moniplus';
    @import './themes/chfx';
    @import './themes/premierfx';
    @import './themes/tdb';
    @import './themes/fm';
    @import './themes/crb';
    @import './themes/shoprite';

    .background {
      background-color: #eeeff0;
      
      &.shadows {
        box-shadow: inset 0 40px 40px -40px #2a2a2a40;
      }

      @media screen and (max-width: 991px) {
        &.shadows {
          box-shadow: inset 0 20px 20px -20px #2a2a2a40;
        }
      }
    }

    &.popover {
      box-shadow: none;
      background-color: #eeeff0;
      padding: 15px;
      border-radius: 15px;
      border-color: #ccd2d4;
      font-family: Poppins, sans-serif;
      .popover-body {
        color: #b2b2af;
        font-size: 14px;
        padding: 0;
      }
    }
    &.popover > .arrow,
    &.popover > .arrow::after {
      border-color: transparent;
      border-style: solid;
      display: block;
      height: 0;
      position: absolute;
      width: 0;
    }
    &.popover > .arrow {
      border-width: 0.78571rem;
    }
    &.popover > .arrow::after {
      border-width: 0.71429rem;
      content: '';
    }
    &.popover.right > .arrow {
      border-left-width: 0;
      border-right-color: rgba(0, 0, 0, 0.25);
      left: -0.78571rem;
      margin-top: -0.78571rem;
      top: 50%;
    }
    &.popover.right > .arrow::after {
      border-left-width: 0;
      border-right-color: #393939;
      bottom: -0.71429rem;
      content: ' ';
      left: 1px;
    }
    &.popover.left > .arrow {
      border-left-color: rgba(0, 0, 0, 0.25);
      border-right-width: 0;
      margin-top: -0.78571rem;
      right: -0.78571rem;
      top: 50%;
    }
    &.popover.left > .arrow::after {
      border-left-color: #393939;
      border-right-width: 0;
      bottom: -0.71429rem;
      content: ' ';
      right: 1px;
    }
    &.popover.bottom > .arrow {
      border-bottom-color: rgba(0, 0, 0, 0.25);
      border-top-width: 0;
      left: 50%;
      margin-left: -0.78571rem;
      top: -0.78571rem;
    }
    &.popover.bottom > .arrow::after {
      border-bottom-color: #393939;
      border-top-width: 0;
      content: ' ';
      margin-left: -0.71429rem;
      top: 1px;
    }
    #sub-navbar {
      box-shadow: 0 1px 0 #e1e1e1;
    }
    .form-control {
      font-weight: 600;
      .chosen-single {
        color: #4f4f4f;
        &.chosen-default {
          color: #919191;
        }
      }
      &:disabled {
        background: #f0f0f0;
      }
    }
    .form-horizontal .control-label {
      font-family: 'inherit';
    }
    .btn {
      border-radius: 3px;
      font-size: 13px;
    }
    .alert-danger-strip {
      background-color: #fa4238;
      color: #ffffff;
    }
    .navbar-inverse .navbar-toggle {
      border-color: #fff;
      &:hover,
      &:focus,
      &:active {
        background-color: #218ecc;
      }
    }
    @media (min-width: 992px) {
      .navbar-collapse {
        max-height: 56px;
        min-height: 56px;
      }
    }
    .navbar-inverse .navbar-collapse,
    .navbar-inverse .navbar-form {
      border-color: #007ec5;
      border: none;
    }
    .scroll-tabs-container {
      &.pills-container {
        div.scroll-tab-inner span {
          &:hover,
          &:focus,
          &.selected {
            background-color: #fff;
            border: 0.0714rem solid #017ac3;
            color: #017ac3;
            border-radius: 1.428rem;
          }
        }
      }
    }
  }
  .content {
    @import './themes/default';
    @import './themes/kiwibank';
    @import './themes/moniplus';
    @import './themes/chfx';
    @import './themes/premierfx';
    @import './themes/tdb';
    @import './themes/fm';
    @import './themes/crb';
    @import './themes/shoprite';
  }
}
