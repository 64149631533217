.confirm-details-container {
  display: flex !important;
}

.confirm-details {
  padding: 0 1.7rem !important;
}

.confirm-details-alert {
  max-height: 50px !important;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.2rem;
}

.navbar.confirm-details {
  max-height: 100px;
}

@media (max-width: 992px) {
  .confirm-details {
    padding: 0 !important;
  }

  .confirm-details-alert {
    width: 100% !important;
    padding: 0 !important;
  }
}

@media (max-width: 467px) {
  .confirm-details-container {
    display: block !important;
    max-width: 100%;
    padding: 0;
  }
  .details-container {
    max-width: 100% !important;
  }

  .navbar.confirm-details {
    max-height: 200px;
  }
}

.personal-ctn {
  padding: 30px;
  position: relative;

  h5 {
    font-family: 'Fredoka One', sans-serif;
    font-size: 24px;
    color: #0a9ba9;
    font-weight: 400;
    padding-bottom: 20px;
  }
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  border: none;
  background: transparent;
  font-size: 20px;
}