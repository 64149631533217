.calculator-container {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  padding: 0 50px 100px;
}

.calculator-container.full {
  max-width: none;
  padding: 0;
}

.calculator {
  display: flex;
  width: 100%;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border:1px solid #ccd2d4;
  border-radius: 15px;
  background-color: #fff;
  color: #666664;
}

.calculator.full {
  border-right-style: none;
  border-left-style: none;
  border-radius: 0px;
}

.calculator-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  align-items: stretch;
  flex: 1;
  grid-auto-columns: 1fr;
  column-gap: 50px;
  row-gap: 50px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.calculator-grid.resize {
  grid-column-gap: 50px;
  grid-template-columns: 1fr auto;
}

.calculator-rows {
  align-items: end;
  column-gap: 50px !important;
  row-gap: 20px;
  grid-template-columns: 1fr !important;
  grid-template-rows: auto 1fr auto;
}

.calculator-rows.columns {
  max-width: none;
  grid-template-columns: 1fr 1fr !important;
  grid-template-rows: 1fr;
  align-items: center;
}

.calculator-rows.column {
  row-gap: 0 !important;
  align-items: center;
  grid-template-rows: 1fr !important;
}

.calculator-label {
  color: #666664;
  line-height: 100%;
  font-weight: 400;
  font-size: 16px;
}

.calculator-form {
  margin-bottom: 0px;
}

.heading.quote-header {
  margin: 0px;
  align-self: flex-start;
  color: #0a9ba9;
  font-size: 24px;
  line-height: 100%;
}

.heading {
  font-family: 'Fredoka One', sans-serif;
  color: #3c3c3b;
  font-weight: 400;
}

.calculator-label {
  color: #666664;
  line-height: 100%;
  font-weight: 400;
}

.form-field.amount {
  margin-bottom: 0px;
  border-right-style: none;
  border-right-width: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: rgba(238, 239, 240, 0.5);
}

@media screen and (max-width: 991px) {
  .calculator.full {
    padding-right: 50px;
    padding-left: 50px;
  }

  .calculator-grid {
    grid-template-columns: 1fr;
  }

  .calculator-grid.resize {
    grid-column-gap: 30px;
    row-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .calculator-rows.columns {
    column-gap: 30px !important;
  }

  .economics-grid.split {
    grid-auto-columns: 1fr;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .calculator-container {
    padding-right: 30px;
    padding-left: 30px;
  }

  .calculator.full {
    padding: 20px 25px;
  } 
  .calculator-grid.resize {
    row-gap: 15px;
  }
  .calculator-rows.columns {
    row-gap: 15px;
  }
}

@media screen and (max-width: 467px) {
  .calculator-container {
    padding-right: 20px;
    padding-left: 20px;
  }

  .calculator {
    padding: 20px;
  }

  .calculator-grid.resize {
      row-gap: 15px;
  }

  .calculator-rows.columns {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr;
  }

  .economics.grid {
    row-gap: 0px !important;
    grid-template-columns: 1fr;
  }

  .economics.buttons {
    row-gap: 20px !important;
    grid-template-columns: 1fr;
  }
}