.page-title.white {
  color: #fff !important;
  font-weight: 400 !important;
  font-family: 'Fredoka One', sans-serif;
  font-size: 50px;
  width: fit-content;
  margin-bottom: 50px;
  align-self: flex-start;

  @media screen and (max-width: 479px) {
    font-size: 40px;
    align-self: center;
  }
}

.bar-1 {
  position: relative;
  z-index: 1;
  margin-left: -20px;
  padding-right: 20px;
  padding-left: 20px;
  border-top: 10px solid #0a9ba9;
  border-bottom: 10px solid #0a9ba9;
  background-color: #0a9ba9;
}

.view-history {
  color: #e60106;
  cursor: pointer;
  text-decoration: underline;
  font-weight: lighter !important;
  width: 100px;
}

.tool-tip {
  position: absolute;
  top: -89px;
  z-index: 5;
  display: none;
  overflow: hidden;
  padding: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  flex: 1;
  object-position: 0% 50%;
  border-radius: 10px;
  border: 1px solid #ccd2d4;
  background-color: #eeeff0;
  box-shadow: 0 0 40px 0 #2a2a2a1a;
}

.tooltip-content {
  width: 100%;
  min-width: 284px;
  grid-column-gap: 20px;
  grid-row-gap: 15px;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}

.tooltip-label {
  margin-bottom: 0px;
  color: #b2b2af;
  white-space: nowrap;
}

.tooltip-text {
  margin-bottom: 0px;
  color: #666664;
  white-space: nowrap;
}

.more-info {
  fill: grey;
  margin-left: 6px;
  width: 15px;
}

.name-column {
  cursor: pointer;
  width: fit-content;
}

.action-icon {
  cursor: pointer;
  width: 14px;
  margin-right: 12px;
  height: 20px;
}

.more-icon {
  cursor: pointer;
  width: 18px;
  margin-left: 15px;
}

.table-icon {
  cursor: pointer;
  width: 16px;
}

.table-icon.trash-icon {
  margin-left: 15px;
}

.placeholder {
  display: none;
}

.item-container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.item-container.last {
  margin-right: 0px;
  margin-bottom: 15px;
}

.item-container.name {
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center;
  text-transform: capitalize;
}

.item-container.text {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0;
}

.history-link {
  width: 100px;
  color: #ed1c24 !important;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.pay-now-button {
  font-size: 16px !important;
  font-weight: normal !important;
  display: flex !important;
  height: 40px;
  margin: 10px 0;
  padding: 5px 20px !important;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 10px !important;
  background-color: #b2b2af !important;
  transition: all 200ms ease-in-out !important;
  color: #fff !important;
}

.pay-now-button:hover {
  background-color: #ed1c24 !important;
  color: #fff !important;
}

.pay-now-button.menu {
  background-color: #ed1c24 !important;
  color: #fff;
  font-size: 105%;
}

.pay-now-button.menu:hover {
  background-color: #c61017;
  color: #fff;
}

.pay-now-button.nav-2 {
  margin-right: 20px;
  margin-left: 20px;
  flex: 0 0 auto;
}

.pay-now-button.secondary {
  max-width: 225px;
  margin-left: 30px;
  padding-right: 18px;
  padding-left: 18px;
  border-style: solid;
  border-width: 2px;
  border-color: #ed1c24;
  background-color: transparent;
  color: #2a2a2a;
}

.pay-now-button.secondary:hover {
  background-color: #ed1c24;
  color: #fff;
}

.table-row {
  display: grid;
  grid-template-columns: 150px 1fr 1fr 1fr;
  column-gap: 30px;
  align-content: center;
  border-bottom: 1px solid #ccd2d4;
  align-items: center;
  padding: 8px 0;
}
.Popover-tip {
  fill: #b2b2af;
}

@media screen and (max-width: 991px) {
  .bar-1 {
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .placeholder {
    display: block;
    color: #b2b2af;
    line-height: 120%;
  }

  .item-container.name {
    font-weight: 600;
  }

  .tool-tip {
    top: 0;
  }

  .tooltip-content {
    min-width: auto;
    font-weight: 400;
  }

  .Popover-tip {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .Popover {
    left: 204px !important;
  }

  .bar-1 {
    margin-left: 0px;
  }

  .tool-tip {
    padding: 25px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 479px) {
  .Popover {
    left: 60px !important;
  }

  .tooltip-content {
    min-width: 200px;
    grid-row-gap: 0px;
    grid-template-columns: auto;
  }

  .tool-tip {
    padding: 20px 20px 10px;
  }
}

@media screen and (max-width: 340px) {
  .Popover {
    left: 30px !important;
  }
}
