/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Poppins:ital,wght@0,400;0,600;1,600&display=swap');
@import 'custom_bootstrap';
@import 'clientfx_font_icons';
@import 'skeleton.scss';
@import 'form-authy.scss';
@import 'super_swift.scss';
$text-muted: #858585;

html,
body {
  max-width: 100%;
}

html {
  min-height: 100%;
  position: relative;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif !important;
}

body {
  position: relative; // fixes popover layout issues
  color: #717171 !important;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #eeeff0 !important;

  .font-13 {
    font-size: 13px;
  }

  .transactions {
    font-size: 16px;
    padding: 15px 0;
  }

  #t-bene {
    margin-left: 5px;
  }

  #trans-status {
    margin-left: 40px;
  }

  #trans-status-2 {
    margin-left: 25px;
  }

  #trans-currency-2 {
    margin-left: 15px;
  }

  #trans-currency {
    margin-left: 35px;
  }

  #trans-bene {
    margin-left: 10px;
  }

  .empty {
    width: 90px;
  }

  .refund-btn {
    margin-top: 0px;
    padding: 7px 15px;
    border-style: solid;
    border-width: 2px;
    border-color: #ed1c24;
    background-color: transparent;
    color: #2a2a2a;
    border-radius: 10px;
    font-size: 16px;
    font-weight: normal;
    min-width: 100px;
    &:hover {
      background-color: #ed1c24;
      color: #fff;
    }
  }

  .checkLocal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
  }

  .personal-det-confirm {
    .modal-content {
      border-radius: 20px;
    }
  }

  .bene-modal {
    font-family: 'Poppins', sans-serif;

    .modal-dialog {
      max-width: 600px !important;
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: 767px) {
      padding: 30px;
      justify-content: flex-start;
    }

    @media screen and (max-width: 479px) {
      padding: 20px;
    }
  }

  .cta-button-block {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 479px) {
      display: block;
    }
  }

  #quote-result {
    background: #fff !important;
    margin: auto;
    padding-bottom: 30px;
    font-family: 'Poppins', sans-serif;
    border-radius: 15px;
  }

  .failed-header {
    background: #fff;
    width: 97%;
    margin: 0 auto;
    padding: 0 10px;
  }

  .bene-modal.confirmed-modal {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;

    .modal-dialog {
      max-width: 765px !important;
    }
    .modal-content {
      border-radius: 15px;
      border: none;
    }
    .modal-body {
      border-radius: 15px;
    }
    .modal-footer {
      font-size: 14px;
    }
    .content-body {
      padding: 30px;

      .col-sm-12 {
        padding: 0 !important;
      }
    }

    @media screen and (max-width: 991px) {
      .modal-dialog {
        max-width: 600px !important;
      }
    }

    @media screen and (max-width: 767px) {
      .content-body {
        padding: 25px;
      }
    }
    @media screen and (max-width: 467px) {
      .content-body {
        padding: 20px;
      }
    }
  }

  .user-name:hover {
    color: #ed1c24;
  }

  .transform-svg {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }

  .bene-modal.view-history {
    text-decoration: none;

    .modal-content {
      background-color: transparent !important;
      font-weight: normal;
      border: none;

      .pagination-table {
        padding: 30px !important;
      }
    }

    @media screen and (max-width: 767px) {
      .modal-dialog {
        height: auto;
      }
      .modal-content {
        .pagination-table {
          padding: 25px !important;
        }
      }
    }

    @media screen and (max-width: 479px) {
      .modal-content {
        .pagination-table {
          padding: 0 !important;
        }
      }
    }
  }

  .bene-modal.view-history {
    .modal-content {
      background-color: transparent !important;
      font-weight: normal;
      border: none;

      .pagination-table {
        padding: 20px !important;
      }
    }
  }

  #ccy-selection-modal {
    .modal-dialog {
      width: 84%;
    }
    .options {
      padding: 1.4285rem 0;
      margin-left: -2.5rem;
      margin-right: -2.5rem;
      > div {
        overflow: hidden;
        float: left;
        width: 25%;
        height: 3.57rem;
        border-right: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        position: relative;
      }
      > div a {
        position: absolute;
        width: 100%;
        padding: 1.07rem;
        color: #555555;
        text-decoration: none;
        b {
          font-weight: normal;
          font-family: 'Montserrat', sans-serif;
        }
        img {
          height: 1.57rem;
        }
        &:hover,
        &:focus,
        &.selected {
          background-color: #f5f5f5;
          color: #555;
        }
      }
      span {
        margin: 0 0 0 0.8571rem;
      }
    }
  }
  .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .well-dark {
    background-color: #e6e6e6;
    display: flex;
    .card-header {
      background-color: #e6e6e6;
    }
  }
  .well-bordered {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .cursor {
    &-pointer {
      cursor: pointer !important;
    }
  }
  .form-control {
    position: relative;
    height: 46px;
    font-weight: 600;
    border: 1px solid #e1e1e1;
    box-shadow: none;
    color: #333;
    &.chosen-container {
      background-color: #f5f5f5;
    }
    &.input-sm {
      font-size: 14px;
    }

    &::placeholder {
      color: #717171;
      font-weight: 400;
    }

    &:focus-visible {
      text-shadow: none;
    }

    .form-control_eye {
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
  }
  .input-group.w-input {
    height: 50px;
    border: 1px solid #ccd2d4;
    border-radius: 10px;
    display: flex;
    background: #eeeff080;
    font-size: 16px;
    font-weight: 400;
  }
  .form-control.w-input {
    height: 50px;
    border: 1px solid #ccd2d4;
    border-radius: 10px;
    display: flex;
    background: #eeeff080;
    font-size: 16px;
    font-weight: 400;

    &:focus {
      box-shadow: none;
    }

    &:active {
      border-color: #b2b2af;
    }

    &::placeholder {
      color: #b2b2af;
    }
  }
  .input-group .form-control {
    position: relative;
    z-index: 0;
  }
  .form-left {
    margin-left: 15px;
  }
  select > div {
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #707070;
    font-weight: 600;
    & div.single {
      display: flex;
      width: 100%;
      height: 45px;
      line-height: 42px;
      & div.toggle {
        line-height: 42px;
      }
      & div.placeholder {
        line-height: 42px;
      }
      & div.value {
        line-height: 42px;
      }
      & div.clear {
        line-height: 42px;
      }
    }
  }
  .birth-date-select {
    width: 32.2%;
    margin-bottom: 0.5rem;
    display: inline;
  }
  .well-strip {
    background-color: #f0f0f0;
    padding: 10px;
    margin: 0px -20px;
  }
  .well-text {
    background-color: #fff8e9;
    padding: 10px;
    margin: 0px -21px;
  }
  .text {
    margin-left: 13px;
  }
  .text-right-label {
    margin-left: 25px;
  }
  .table-drop {
    overflow: visible;
  }
  section {
    padding-top: 65px;
  }
  .logo-image {
    max-width: 190px;
  }
  .no-padding {
    padding: 0;
  }
  .no-margin {
    margin: 0;
  }
  .no-background {
    background: none;
  }
  .no-border {
    border: none !important;
  }
  .font-bold {
    font-weight: 600;
    color: #333333;
  }
  .fa.fa-question-circle.fa-lg {
    color: #c0c0c0;
  }
  .well-warning {
    background-color: #fff8e9;
    border: 2px solid #fddc92;
  }
  .well-success {
    background-color: #e9f6ef;
    border: 2px solid #94d0b1;
  }
  .well-danger {
    background-color: #fff2f2;
    border: 2px solid #ff7f80;
  }
  .well-info {
    background-color: #fff4e8;
    border: 2px solid #ffc68f;
  }
  .text-muted {
    color: $text-muted;
  }
  .form-group {
    .search-form-field.form-control {
      position: absolute;
      border-radius: 3px;
      font-family: 'Open Sans';
    }
    .input-group-btn {
      z-index: 10;
    }
    .form-control-container {
      position: relative;
      .form-control_eye {
        position: absolute;
        right: 20px;
        bottom: 15px;
        background-color: white;
        cursor: pointer;
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
  .input-lg,
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .btn {
    border-radius: 0.21429rem;
    font-size: 1.586rem;
    line-height: 1.33333;
    padding: 1.02rem 0.85rem;
  }
  .form-group.required .control-label:after,
  label.required:after {
    content: '*';
    color: #fa4238;
  }
  .search-form .form-control {
    font-weight: normal;
    &.input-sm {
      border-radius: 3px;
      box-shadow: none;
      height: 40px;
      padding: 9px 12px;
    }
  }
  .icon-search {
    color: #c1c1c1;
    font-size: 15px;
    line-height: 1.4;
    font-weight: bolder;
  }
  .country_flag {
    height: 3rem;
    margin-right: 0.625rem;
  }
  .btn.btn-sm {
    font-size: 13px;
    padding: 9px 14px;
    &.btn-default {
      border: 1px solid #851718;
      color: #fff;
      background-color: #851718;
      margin-left: 10px;
    }
  }
  .btn {
    font-weight: 600;
    &.edit,
    &.delete {
      background-color: #c1c1c1;
      height: 33px;
      padding: 0;
      width: 33px;
      border-radius: 6px;
      i {
        color: #fff;
        font-size: 13px;
      }
    }
    + .btn {
      margin-left: 7px;
    }
  }
  .sort_both,
  .sort_asc,
  .sort_desc {
    cursor: pointer;
    .sort-icon--sort_both {
      content: '\f0de';
      vertical-align: -0.25rem;
    }
  }
  .infinite-scroller {
    height: 100%;
  }
  table.table {
    thead {
      th {
        background-color: #f0f0f0;
        border-color: #f0f0f0;
        color: #919191;
        font-weight: 600;
        font-size: 13px;
        padding: 12px 18px;
        height: 55px;
        vertical-align: middle;
      }
    }
    th,
    td {
      padding: 22px 14px 18px;
      vertical-align: middle;
    }
    border: 10px solid #f0f0f0;
    border-top: none;
    a {
      text-decoration: none;
      cursor: pointer;
    }
    &.table--small-padding {
      tr:hover {
        background-color: #f6f6f6;
        cursor: default;
      }
      td {
        padding: 0.5rem 1rem;
        color: #717171;
      }
    }
    &.table-hover {
      tr:hover {
        background-color: #f6f6f6 !important;
        cursor: default;
      }
    }
  }
  .form-table {
    display: table;
    border: 10px solid #f0f0f0;
    border-top: none;
    .thead {
      display: table-header-group;
    }
    .th {
      background-color: #f0f0f0;
      border-color: #f0f0f0;
      color: #919191;
      font-weight: 600;
      font-size: 13px;
    }
    .tbody {
      display: table-row-group;
    }
    .tr {
      display: table-row;
    }
    .th,
    .td {
      display: table-cell;
      padding: 22px 20px 18px;
      vertical-align: middle;
    }
  }
  .mask_span {
    margin-left: 12px;
    width: 80%;
    background: transparent;
    position: absolute;
    overflow: hidden;
    text-overflow: initial;
    z-index: 9;
    display: block;
    margin-top: 9px;
  }
  .mask_input {
    background: transparent;
    color: transparent;
    border-radius: 0;
  }
  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .unselectable.w-mask-input {
    height: 50px;
    border: 1px solid #ccd2d4;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    display: flex;
    background: #eeeff080;
    font-size: 16px;
    font-weight: 400;
  }
  .input-group-text.w-mask-input {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    font-size: 18px;
    padding: 0 18px;
    color: #666664 !important;
    background-color: #b2b2af;
  }

  .sticky-table-header {
    table {
      tbody {
        display: block;
        max-height: 50rem;
        overflow: auto;
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    }
  }

  .divider {
    margin: 30px 0;
    align-self: stretch;
    border-top: 1px solid #ccd2d4;
  }

  .w-layout-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }

  .w-layout-grid.economics {
    align-items: center;
    grid-column-gap: 20px;
    row-gap: 0;
    grid-template-rows: auto;

    @media screen and (max-width: 467px) {
      row-gap: 0px !important;
      grid-template-columns: 1fr !important;
    }
  }

  .w-layout-grid.economics-grid {
    margin-bottom: 10px;
    align-items: center;
    grid-column-gap: 50px !important;
    row-gap: 15px;
    grid-template-columns: 1fr !important;
    font-size: 16px;
  }

  .w-layout-grid.economics-grid.split {
    height: 50px;
    margin-bottom: 0px;
    justify-items: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-template-columns: auto auto !important;
    grid-template-rows: auto;
  }

  .content-container {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 100px 50px;

    @media screen and (max-width: 991px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
      padding-right: 30px;
      padding-left: 30px;
    }

    @media screen and (max-width: 479px) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .heading {
    font-family: 'Fredoka One', sans-serif;
    color: #3c3c3b;
    font-weight: 400;
  }

  .heading.panel-header {
    margin-top: 0px;
    margin-bottom: 0px;
    align-self: flex-start;
    color: #0a9ba9;
    font-size: 24px;
    line-height: 100%;

    @media screen and (max-width: 467px) {
      text-align: center;
    }
  }

  .heading.confirmation-header {
    margin: 30px 0;
    align-self: flex-start;
    color: #3c3c3b;
    font-size: 24px;
    line-height: 100%;
  }

  .cta-button {
    display: flex;
    height: 50px;
    min-width: 100px;
    padding: 10px 20px;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    flex: 0 0 auto;
    border-radius: 10px;
    background-color: #ed1c24;
    transition: all 200ms ease-in-out;
    color: #fff !important;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    border-color: transparent;

    &:hover {
      background-color: #c61017;
      color: #fff !important;
      text-decoration: none;
    }
  }

  .cta-button.secondary {
    padding: 0 18px;
    align-self: center;
    border-style: solid;
    border-width: 2px;
    border-color: #ed1c24;
    background-color: transparent;
    color: #2a2a2a !important;
    margin-top: 25px;
    text-decoration: none;

    &:hover {
      background-color: #ed1c24;
      color: #fff !important;
    }

    @media screen and (max-width: 991px) {
      max-width: none;
      margin-left: 0px;
    }

    @media screen and (max-width: 767px) {
      height: 45px;
    }

    @media screen and (max-width: 479px) {
      margin-top: 20px;
      align-self: stretch;
    }
  }

  .cta-button.secondary.grey {
    border-color: #ccd2d4;
    color: #b2b2af !important;

    &:hover {
      border-color: #b2b2af;
      background-color: #b2b2af;
      color: #666664 !important;
    }
  }

  /* loading dots */
  .loading:after {
    content: ' •';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }

  /* Fix NgSelect options to get over input_mask */
  select-dropdown > div {
    z-index: 10;
  }

  /* Toast flash message */
  #toast-container {
    .toast-message {
      text-align: center;
    }
  }

  $margins: 4 6 8 10 16 20 30 40 50;
  @each $n in $margins {
    .space-#{$n} {
      margin: #{$n / 14}rem 0;
    }
  }
  footer {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .word-wrap {
    word-wrap: break-word;
  }
  .modal {
    color: #717171 !important;
  }
}

.hidden {
  display: none !important;
}

/* SELECT */
.custom-form-select {
  .select__control {
    background: #f5f5f5;
    height: 46px;
    color: #919191;
    outline: none;
    overflow: hidden;
    font-size: 14px;
  }
  .select-menu {
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 5px;
    padding-bottom: 10px;
    max-height: 300px;
    max-width: 500px;
    border-radius: 5px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    z-index: 1000;
    background-color: white;
    opacity: 0;
    &.select-menu--bottom {
      top: 50px;
      opacity: 1;
    }
    &.select-menu--top {
      bottom: auto;
      opacity: 1;
    }
  }
  .select-option_checkbox,
  .select-option {
    background-color: #fff;
    padding: 10px 12px;
    &:hover {
      background-color: #f4f4f4;
    }
  }
  .select-option_checkbox {
    padding: 0 12px;
    &:hover {
      background-color: transparent;
    }
    input {
      margin-right: 6px;
    }
    label {
      margin-top: 2px;
    }
  }
  .select-option--hovered {
    background-color: #f4f4f4;
  }
}

.w-select {
  margin-left: 30px;
  width: 250px;

  .select__control {
    height: 50px !important;
    background-color: #eeeff080 !important;
    border: 1px solid #ccd2d4 !important;
    border-radius: 10px !important;
    width: 100% !important;
    font-size: 16px;
    color: #b2b2af !important;

    .select__indicator-separator {
      display: none;
    }

    .select-clear-icon {
      display: none;
    }
  }

  .select__menu {
    z-index: 5;
  }

  @media screen and (max-width: 1024px) {
    width: 160px !important;
    margin-left: 20px !important;
  }

  @media screen and (max-width: 991px) {
    margin-left: 0 !important;
    width: 100% !important;
    #trans-status {
      margin-left: 0 !important;
    }

    #trans-currency {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  #trans-status,
  #trans-currency,
  #history,
  #trans-bene,
  #trans-status-2,
  #trans-currency-2,
  #t-bene {
    margin-left: 0 !important;
  }
}

.open-status {
  border-color: #2468ae;
  background-color: #e6f0fa;
}

.confirmed-status {
  border-color: #fd689e;
  background-color: #ffedf4;
}

.partially-settled-status {
  border-color: #f8ad08;
  background-color: #fffae7;
}

.settled-status {
  border-color: #f8ad08;
  background-color: #fffae7;
}

.released-status {
  border-color: #622188;
  background-color: #eed0ff;
}

.paid-status {
  border-color: #16b08f;
  background-color: #d6fff9;
}

.completed-status {
  border-color: #0a9ba9;
  background-color: #0a9ba94d;
}

.cancelled-status {
  border-color: #ed1c24;
  background-color: #ed1c244d;
}

.pending-status {
  border-color: #2468ae;
  background-color: #e6f0fa;
}

.pending-payment-status {
  border-color: #ff7600;
  background-color: #ff76004d;
}

.failed-status {
  border-color: #ed1c24;
  background-color: #ff8b91;
}

.default-status {
  border-color: #212529;
  background-color: #e91f29;
}

:is(.open-status, .paid-status, .failed-status, .cancelled-status, .completed-status, .partially-settled-status, .pending-payment-status, .released-status, .pending-status, .confirmed-status, .settled-status, .default-status) {
  display: flex;
  height: 30px;
  width: 112px;
  padding: 5px 15px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
  color: #2a2a2a;
  font-size: 14px;
}

.select_value-container {
  display: flex;
  flex: 1;
  padding: 8px;
}
.custom-select_multi-indicator {
  background: #f5f5f5 url(/assets/images/up-down-arrow.png) no-repeat right 6px center;
  width: 38px;
  height: 46px;
}
.select-clear-icon {
  font-size: 20px;
  padding: 0.5rem;
  cursor: pointer;
}

.width-100 {
  width: 100px !important;
}
.width-200 {
  width: 200px !important;
}
.width-250 {
  width: 250px !important;
}
.auth-header {
  font-family: 'Fredoka One', sans-serif !important;
  color: #3c3c3b !important;
  font-weight: 400;
  font-size: 35px;
  line-height: 100%;
}
.btn-flat {
  background-color: transparent;
  color: #666 !important;
}
.btn-secondary {
  background-color: #e6e6e6 !important;
  color: #333 !important;
  border-color: #b3b3b3 !important;
}
.btn-tbd-red {
  background-color: #ea212d !important;
  color: #fff !important;
  border-color: #ea212d !important;
}

::marker {
  font-size: 10px;
}

button:focus {
  outline: none !important;
}
.panel-wrapper {
  border-radius: 2px;
  border-style: solid;
  border-width: 8px !important;
  border-color: #e6e6e6;
}
.badge-info {
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  width: fit-content;
  border-radius: 2px;
}
