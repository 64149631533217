.grid-areas {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto;
  gap: 10px 0px;
  grid-template-areas: '. .';
  box-sizing: border-box;

  .green {
    color: #0a9ba9;
  }
  .red {
    color: #ed1c24;
  }
  :is(.green, .red) {
    font-weight: 600;
  }
}

.font-pop {
  font-family: 'Poppins', sans-serif;
}
.font-fred {
  font-family: 'Fredoka One', sans-serif;
}

.details-modal-header {
  background-color: #0a9ba9;
  border-radius: 15px 15px 0 0 !important;
  border: 0 !important;
  color: white;
  font-size: 16px;
  justify-content: center !important;
}
  .details-modal-header-content {
    margin: 6px 24px;
  }
    .svg-tick {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      path {
        fill: white !important;
      }
    }

.modal-content {
  border: 1px solid transparent !important;
  border-radius: 20px 20px 15px 15px !important;
}

.details-header {
  color: #3c3c3b;
  font-family: 'Fredoka One', sans-serif;
  font-size: 24px;
  margin: 30px 0  0 3rem;
}

.details-container {
  font-size: 16px;
  color: #666664;
}

.hr-spacer {
  margin: 30px 0;
}

ol.list {
  padding-inline-start: 1rem;
  font-size: 14px;
}

ol.list li::marker {
  font-size: 14px;
}