@font-face {
  font-family: 'client-fx';
  src:url('../fonts/client-fx/client-fx.eot');
  src:url('../fonts/client-fx/client-fx.eot') format('embedded-opentype'),
    url('../fonts/client-fx/client-fx.ttf') format('truetype'),
    url('../fonts/client-fx/client-fx.woff') format('woff'),
    url('../fonts/client-fx/client-fx.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'globe';
  src: url('../fonts/client-fx/globe.eot');
  src: url('../fonts/client-fx/globe.eot') format('embedded-opentype'),
       url('../fonts/client-fx/globe.woff') format('woff'),
       url('../fonts/client-fx/globe.ttf') format('truetype'),
       url('../fonts/client-fx/globe.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
.icon
{
  font-family: "globe";
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.clientfx-base-styles {
  font-family: 'client-fx';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='icon-'], [class*=' icon-'] {
  @extend .clientfx-base-styles;
}
.icon-2x {
  font-size: 2em;
}
.icon-add-ben:before {
  content: '\e630';
}
.icon-comp-tr:before {
  content: '\e631';
}
.icon-my-account:before {
  content: '\e62d';
}
.icon-support:before {
  content: '\e62e';
}
.icon-tour2:before {
  content: '\e62f';
}
.icon-left-menu:before {
  content: '\e62c';
}
.icon-star:before {
  content: '\e62b';
}
.icon-pencil:before {
  content: '\e620';
}
.icon-export-svg:before {
  content: '\e61e';
}
.icon-account-details:before, .icon-list-alt:before {
  content: '\e600';
}
.icon-alert:before {
  content: '\e601';
}
.icon-alert-negative:before {
  content: '\e602';
}
.icon-alert-positive:before {
  content: '\e603';
}
.icon-arrow-down:before {
  content: '\e604';
}
.icon-arrows:before {
  content: '\e605';
}
.icon-beneficiaries:before {
  content: '\e606';
}
.icon-calendar:before {
  content: '\e607';
}
.icon-calendar-arrow-left:before {
  content: '\e608';
}
.icon-calendar-arrow-right:before {
  content: '\e609';
}
.icon-checkmark:before {
  content: '\e60b';
}
.icon-close:before {
  content: '\e60c';
}
.icon-collapse:before {
  content: '\e60d';
}
.icon-currency:before {
  content: '\e60e';
}
.icon-dashboard:before {
  content: '\e60f';
}
.icon-delete:before {
  content: '\e610';
}
.icon-download:before {
  content: '\e611';
}
.icon-dropdown-arrows:before {
  content: '\e612';
}
.icon-expand:before {
  content: '\e614';
}
.icon-history:before {
  content: '\e615';
}
.icon-hold:before {
  content: '\e616';
}
.icon-info:before {
  content: '\e617';
}
.icon-limit-orders:before {
  content: '\e618';
}
.icon-link:before {
  content: '\e619';
}
.icon-move:before {
  content: '\e61a';
}
.icon-new-transfer:before {
  content: '\e61b';
}
.icon-new-transfer-alternative:before {
  content: '\e61c';
}
.icon-pay-their-money:before {
  content: '\e61f';
}
.icon-recurring-payments:before {
  content: '\e621';
}
.icon-remove:before {
  content: '\e622';
}
.icon-search:before {
  content: '\e623';
}
.icon-select-currency:before {
  content: '\e624';
}
.icon-suspend:before {
  content: '\e625';
}
.icon-tour:before {
  content: '\e626';
}
.icon-update:before {
  content: '\e627';
}
.icon-upload:before {
  content: '\e628';
}
.icon-watch:before {
  content: '\e62a';
}
.icon-cancel:before {
  content: '\e60a';
}
.icon-edit:before {
  content: '\e613';
}
.icon-notification-bell:before {
  content: '\e61d';
}
.icon-user:before {
  content: '\e629';
}
.globe-1icon-globe:before {
  content: '\e801';
}
