.default {
  $navbar-inverse-bg: #007ec5;
  $navbar-inverse-link-color: #c1e0f1;
  $navbar-inverse-link-active-bg: #218ecc;
  $navbar-inverse-link-transform: capitalize;
  $navbar-font-family: 'Open Sans';
  $navbar-fonclientfx_font_iconst-weight: 600;
  $nav-link-padding: 20px;
  $navbar-font-size: 14px;
  $navbar-inverse-link-active-color: #f0f0f0;
  $link-color: #007dc4;
  $link-hover-color: #2daaf1;
  $footer-bg: #007ec5;
  $footer-color: #fff;
  $btn-primary: #f0b601;
  $btn-primary-border: #f0b601;
  $btn-theme: #fff;
  $btn-default: #f0b601;
  $btn-default-border: #fff;
  $btn-success: #f0b601;
  $btn-success-border: #fff;
  $sub-navbar-bg: #fff;
  $subnav-link-color: #7d7d7d;
  $subnav-link-active-bg: transparent;
  $subnav-link-active-color: #007ec5;
  $subnav-padding: 14px 18px;
  $subnav-font-family: 'Montserrat';
  $subnav-font-size: 13px;
  $checkbox-success: #1ea365;
  $checkbox-success-border: #1ea365;
  $checkbox-primary: #0081c2;
  $checkbox-primary-border: #0081c2;
  $checkbox-warning: #fddc92;
  $checkbox-warning-border: #fddc92;
  $checkbox-danger: #ff7f80;
  $checkbox-danger-border: #ff7f80;
  @import '~assets/styles/layout';
}