.customRadio {
  label {
    font-size: 0.9rem;
  }
  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + label:before {
    margin-right: 10px;
    content: '';
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 4px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #b3b3b3;
    background-color: #fff;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type='radio']:checked + label:before {
    background-color: red;
  }
}

.check {
  font-size: 13px;
}

/* styles for svg icons */
.valid {
  display: flex;

  img {
    height: 17px;
    width: 17px;
    margin-top: 5px;
    margin-right: 10px;
  }
}

.more {
  min-height: 1.4rem;
  min-width: 1.4rem;
  max-height: 1rem;
  max-width: 1rem;
  margin-top: 5px;
  margin-right: 15px;
}

.alertSS {
  border: 1px solid #0a9ba9;
  background: #0a9ba927 0% 0% no-repeat padding-box;
  border: 1px solid #0a9ba9;
  border-radius: 10px;
  color: #00788f;
  text-align: left;
  font: normal normal normal 14px/20px 'Poppins';
  color: #00788f;
  padding: 15px;
}
.btnFlatSs {
  text-align: left;
  font: normal normal normal 16px/24px 'Poppins';
  color: #b2b2af;
  text-decoration: none !important;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    color: #0a9ba9;
  }
}
.otpContainer {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-bottom: 0px;
  + div {
    display: flex;
    width: 34rem;
    max-width: 34rem;
    padding: 32px 40px 32px;
    color: #b2b2af;
    font: normal normal normal 14px/20px 'Poppins';
    text-align: left;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #3c3c3b;
  }
}
