.fm {
  $navbar-inverse-bg: #1a1a1a;
  $navbar-inverse-link-color: #919191;
  $navbar-inverse-link-active-bg: #2c2c2c;
  $navbar-inverse-link-transform: capitalize;
  $navbar-font-family: 'Open Sans';
  $navbar-font-weight: 600;
  $nav-link-padding: 20px;
  $navbar-font-size: 14px;
  $navbar-inverse-link-active-color: #f0f0f0;
  $link-color: #2ba660;
  $link-hover-color: #2ba660;
  $footer-bg: #f5f5f5;
  $footer-color: #b5b5b5;
  $btn-primary: #2ba660;
  $btn-primary-border: #fff;
  $btn-theme: #fff;
  $btn-default: #2ba660;
  $btn-default-border: #fff;
  $btn-success: #2ba660;
  $btn-success-border: #fff;
  $sub-navbar-bg: #2c2c2c;
  $subnav-link-color: #2ba660;
  $subnav-link-active-bg: transparent;
  $subnav-link-active-color: #fff;
  $subnav-padding: 14px 18px;
  $subnav-font-family: 'Montserrat';
  $subnav-font-size: 13px;
  $checkbox-success: #1ea365;
  $checkbox-success-border: #1ea365;
  $checkbox-primary: #0081c2;
  $checkbox-primary-border: #0081c2;
  $checkbox-warning: #fddc92;
  $checkbox-warning-border: #fddc92;
  $checkbox-danger: #ff7f80;
  $checkbox-danger-border: #ff7f80;
  @import '~assets/styles/layout';
}
