.container-send-money {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 10rem;
}

.content-container-send {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
  border-color: 1px solid #ccd2d4;
  border-radius: 15px;
  background-color: #fff;
  transition: all 200ms ease-in-out;
}

.content-container-send.send-money {
  overflow: hidden;
  width: 100%;
  max-width: 468px;
  margin-top: 10vh;
  align-self: center;
  color: #666664;
  object-position: 50% 50%;
}

.radio-button-field {
  display: flex;
  margin-bottom: 10px;
  padding-left: 0px;
  align-items: center;
}

.label.radio {
  margin-right: 10px;
  margin-left: 10px;
  color: #666664;
  font-size: 16px;
  padding-left: 0 !important;
}

.w-form-label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0;
  font-size: 16px;
}

.payment-icon {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccd2d4;
  border-radius: 50%;
  background-color: #eeeff080;
  font-size: 16px;
}

.checkbox-button-field {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  color: #666664;

  label {
    margin-top: 0 !important;
  }
}

.label.checkbox-text {
  margin-left: 10px;
  color: #666664;
}

.step-1.cta-button {
  margin-top: 20px;
}

.step-2-content.cta-button {
  margin-top: 20px;
}

.radio-button {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-left: auto;
  order: 1;
  flex: 0 0 auto;
  border: 1px solid #ccd2d4;
  border-radius: 50%;
  background-color: rgba(238, 239, 240, 0.5);
  transition: all 200ms ease;
  color: #fff;
  background-position: 50% 50%;
  background-size: 0px 0px;

  &:focus, &:hover {
    border-color: #ed1c24;
    box-shadow: none;
    cursor: pointer;
  }
}

.radio-button.w--redirected-checked {
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-color: #c61017;
  background-color: #ed1c24;
  background-image: url('../../../../../../assets/svg/check_box.svg');
  background-position: 50% 50%;
  background-size: 10px 10px !important;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .container-send-money {
    padding: 30px;
  }

  .content-container-send.send-money {
    max-width: none;
  }
}

@media screen and (max-width: 467px) {
  .container-send-money {
    padding: 20px;
  }

  .content-container-send.send-money {
    padding: 20px;
  }

  .heading.panel-header {
    text-align: center;
  }
}


.recipient-currency .select__menu {
  width: 490px !important;
  right: 0;
}
  .counter-currency-select-list-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    justify-content: space-between;
    column-gap: 30px; // prevents country value from showing when selected
  }
  .counter-currency-select-list-item-currency {
    align-self: center;
    text-align: right;
  }