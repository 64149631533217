.form-field.currency-calculator {
  width: auto;
  max-width: 125px;
  display: flex;
  align-items: center;
  min-width: 115px;
  margin-left: 0px;
  padding: 0;
  flex-direction: row;
  justify-content: flex-start;
  flex: 0 auto;
  border: 0px none transparent;
  border-radius: 0px 10px 10px 0px;
  background-color: #3c3c3b;
  color: #fff;

  .select-disabled {
    width: 125px;
    .select__indicators {
      display: none;
    }
  }
  .select-active {
    width: 125px;
    .select__indicators {
      display: flex;
    }
  }
  .select__control {
    background-color: transparent !important;
    border: none !important;
    font-size: 16px;
    color: #fff;

    .select__placeholder {
      color: #fff;
    }
    .select__input {
      color: #fff;

      input {
        font-weight: normal;
      }
    }
  }
  .select__menu {
    z-index: 5;
  }
  .select__single-value {
    color: #fff !important;
  }
}

.form-field.currency-calculator.zar {
  justify-content: flex-start;
}

@media screen and (max-width: 467px) {
  .form-field.currency-calculator {
    max-width: 90px;
    min-width: 90px;
    
    .select__control {
      display: inline-flex;
      .select__value-container {
        width: 50px;
      }
      img {
        display: none;
      }
    }
  }
}