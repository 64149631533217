.register-tnc {
  font-size: 12px;

  a {
    text-decoration: underline;
    color: #666664;
  }
  a:hover {
    text-decoration: none;
    color: #666664;
  }
}
