.progressColumnsContainer {
  display: grid;
  width: 100%;
  max-width: 45rem;
  margin-right: auto;
  margin-bottom: 3rem;
  margin-left: auto;
  grid-column-gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #ffffff;
    overflow-wrap: break-word;
  }
}
.progressColumnsContainer div:nth-child(5) > div::after,
.progressColumnsContainer div:nth-child(1) > div::before {
  display: none;
}
.circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  background: transparent;
  margin-bottom: 1rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0.44em;
    left: 100%;
    width: 380%;
    height: 0.15em;
    background: #ffffff;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0.44em;
    left: -380%;
    width: 380%;
    height: 0.15em;
    background: #ffffff;
  }
}

.redBefore {
  &::before {
    background: #c61017 !important;
    width: 370% !important;
  }
}

.passedCircle {
  border: 2px solid #c61017 !important;
  background: #ed1c24 !important;
  &::after,
  &::before {
    background: #c61017 !important;
  }
}
.activeCircle {
  border-radius: 50%;
  margin: 2px 0px 0px 2px;
  width: 12px;
  height: 12px;
  background: #ffffff;
}

.fredoka50 {
  font-family: 'Fredoka One', sans-serif;
  color: #ffffff;
  font-size: 50px;
  text-align: center;
  margin-bottom: 2rem;
  line-height: normal;
}
.passedCheckmark {
  margin: 3px 0px 0px 5px;
  width: 6px;
  height: 9px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media screen and (max-width: 479px) {
  .progressColumnsContainer {
    max-width: 25rem;
    > div {
      font-size: 14px;
    }
  }
  .container-1200.flex-align-center {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .circle {
    &::after {
      left: 100%;
      width: 200%;
    }
    &::before {
      left: -200%;
      width: 200%;
    }
  }
  .redBefore {
    &::before {
      width: 180% !important;
    }
  }
}
