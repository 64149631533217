$navbar-inverse-bg: #1a1a1a !default;
$navbar-inverse-link-color: #919191 !default;
$navbar-inverse-link-active-bg: #2c2c2c !default;
$navbar-inverse-link-transform: capitalize !default;
$navbar-font-family: 'Montserrat' !default;
$nav-link-padding: 22px 25px !default;
$navbar-font-size: 12px !default;
$navbar-font-weight: normal !default;
$navbar-inverse-link-active-color: #f0f0f0 !default;
$navbar-inverse-link-icon-hover-color: #2ba660 !default;
$footer-bg: #f5f5f5 !default;
$footer-color: #b5b5b5 !default;
$link-color: #2ba660 !default;
$link-hover-color: #247c49 !default;
$text-muted: #858585 !default;
$sub-navbar-bg: #2c2c2c !default;
$subnav-link-color: #7d7d7d !default;
$subnav-link-active-bg: transparent !default;
$subnav-link-active-color: #fff !default;
$subnav-padding: 18px 15px !default;
$subnav-font-family: 'Open Sans' !default;
$subnav-font-size: 13px !default;
$btn-default: #2c2c2c !default;
$btn-success: #1ea365 !default;
$btn-muted: #e5e5e5 !default;
$btn-success-border: #1ea365 !default;
$scroll-tab-inner: #2c2c2c !default;
$checkbox-success: #1ea365 !default;
$checkbox-success-border: #1ea365 !default;
$checkbox-primary: #0081c2 !default;
$checkbox-primary-border: #0081c2 !default;
$checkbox-danger: #ff7f80 !default;
$checkbox-danger-border: #ff7f80 !default;
$checkbox-warning: #fddc92 !default;
$checkbox-warning-border: #fddc92 !default;
$icon-new-transfer: #707070 !default;
$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;
$auth-header-font-family: 'Open Sans', sans-serif !default;
