.group {
  padding: 12px 25px;
  font-family: 'Poppins', sans-serif;
  background: #fff;
}
.group::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100vw;
  left: calc(-50vw + 50%);
  border-bottom: 1px solid rgb(242, 243, 243);
}

.group-ctn {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.group-ctn-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-ctn-logo-2 {
  width: 55%;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}

.group-links-right {
  margin-right: 30px;
}

.group-ctn-img {
  width: 24px;
  height: 24px;
  margin-right: 7px;

  img {
    width: 100%;
  }
}

.group-links {
  a:hover {
    text-decoration: none;
  }

  span {
    margin-left: 10px;
    color: #3c3c3b;
    font-size: 14px;
  }

  span:hover {
    color: #0a9ba9;
  }
}

.by-sgc {
  font-size: 14px;
  color: #2a2a2a;
}

@media only screen and (max-width: 760px) {
  .group-ctn {
    max-height: 30px;
  }

  .group-ctn-logo {
    flex-direction: column;
    align-items: flex-start;
  }

  .group-ctn-img {
    margin-bottom: 5px;
  }

  .group-ctn-logo-2 {
    flex-direction: column;
    align-items: flex-end;
  }

  .group-links-right {
    margin-bottom: 5px;
    margin-right: 0;
    span {
      font-size: 0.8rem;
    }
  }

  .down {
    margin-bottom: -10px;
    span {
      font-size: 0.8rem;
    }
  }
  .by-sgc {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 479px) {
  .group {
    padding-bottom: 5px !important;
  }
  .group-ctn {
    max-height: 100%;
    flex-direction: column;
    .group-ctn-logo-2 {
      flex-direction: row;
      width: 100%;
      margin-left: 0;
      align-items: flex-start;
      justify-content: space-between;
    }
    .group-ctn-logo {
      flex-direction: row;
    }
  }
}
