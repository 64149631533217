.pagination-table {
  font-family: 'Poppins', sans-serif;
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #ccd2d4;
  padding: 30px;
  background-color: #fff;
  align-self: stretch;
  align-items: stretch;
}

.negative-margin {
  margin-top: -30px;
}

.pagination-table-top {
  row-gap: 30px;
  border: none;
  align-items: center;

  .custom-form-select {
    height: 50px;
  }

  h2 {
    font-family: 'Fredoka One', sans-serif;
    font-size: 24px;
    color: #0a9ba9;
    font-weight: 400;
  }
}

.render-input {
  font-size: 16px;
}

.w-inline-block-button,
.w-inline-block-button-load-more {
  margin-top: 0px;
  padding: 9px 14px;
  border-style: solid;
  border-width: 2px;
  border-color: #ed1c24;
  background-color: transparent;
  color: #2a2a2a;
  border-radius: 10px;
  font-size: 16px;
  font-weight: normal;

  &:hover {
    background-color: #ed1c24;
    color: #fff;
  }
}

.table-filters {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
}

.sort-filter {
  display: none;
}

.btn-below {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  height: 80px;

  button {
    position: absolute;
  }
}

.grid-rows {
  display: grid;
  margin-top: 15px;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr;
}

.grid-rows.history {
  margin-top: -30px;
}

.grid-rows.transactions,
.grid-rows.limits {
  margin-top: -30px;
}

.rows {
  display: grid;
  height: auto;
  min-height: 60px;
  justify-items: start;
  align-items: center;
  grid-column-gap: 30px;
  grid-template-rows: auto;
  border-bottom: 1px solid #ccd2d4;
  transition: all 200ms ease;
  color: #666664;
  line-height: 120%;

  &:hover {
    background-color: rgba(238, 239, 240, 0.5);
  }
}

.transactions-header {
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr 1.5fr 0.5fr 1fr 1.5fr 0.5fr 0.5fr;
  grid-column-gap: 30px;
  height: 60px;
  color: #b2b2af;
  border-bottom: 1px solid #ccd2d4;
}

.rows.transactions {
  grid-template-columns: 1fr 1.5fr 0.5fr 1fr 1.5fr 0.5fr 0.5fr;
}

.rows.header {
  height: 60px;
  grid-column-gap: 30px;
  color: #b2b2af;

  &:hover {
    background-color: transparent;
  }
}

.rows.header-limits {
  height: 60px;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  color: #b2b2af;
}
.rows.header.recipients {
  grid-template-columns: 1.5fr 1fr 1fr 90px 100px 60px;

  .item-container {
    cursor: pointer;
  }
}

.rows.header.limits {
  grid-template-columns: 1fr 1fr 1fr;
}

.rows.header.transactions {
  grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
}

.rows.header.history {
  color: #b2b2af;
}

.rows.limits {
  height: 60px;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
}

.rows.header.modal-table-header {
  height: 60px;
  grid-column-gap: 30px;
  grid-template-columns: 150px 1fr 1fr 1fr;
  color: #b2b2af;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
}

.rows.recipients {
  grid-template-columns: 1.5fr 1fr 1fr 90px 100px 60px;
}

.rows.history {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.item-container {
  display: flex;
  align-items: center;
}

.pagination-wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
  color: #b2b2af;
  font-size: 14px !important;
  line-height: 142.857143%;
}

.pagination {
  margin-top: 10px;
  font-size: 14px !important;
}

.item-container-pagination {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-top: 10px;
}

.icon-small-dropdown {
  margin-right: 10px;
  margin-left: 5px;
}

.icon-left {
  margin-right: 10px;
  color: #b2b2af;
}

.icon-right {
  margin-left: 10px;
}

.pagination-icon {
  width: 12px;
}

.cta-button-block {
  display: flex;
  justify-content: center;
}

.new-tran {
  width: 200px;
  margin-left: 20px;

  button {
    margin-top: 0 !important;
  }
}

.close-modal {
  position: absolute;
  left: auto;
  top: 8px;
  right: 30px;
  bottom: auto;
  margin-right: -5px;
  padding: 0px 5px;
  align-self: center;
  cursor: pointer;
  font-size: 16px;
  color: #b2b2af;
}

@media screen and (max-width: 991px) {
  .pagination-table-top {
    align-items: flex-start;
  }

  .table-filters {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    width: 100%;
  }

  .sort-filter {
    display: block;
  }

  .new-tran {
    margin-left: 0;
    width: 100% !important;

    button {
      width: 100%;
    }
  }

  .w-inline-block-button-table {
    margin-top: 20px !important;
  }

  .close-modal {
    top: 17px;
  }

  .grid-rows {
    margin-top: 30px;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  .grid-rows.history {
    grid-template-columns: 1fr;
    margin-top: -20px;
  }

  .rows {
    padding: 30px 0 30px;
    justify-content: start;
    align-items: start;
    align-content: start;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr !important;

    &:nth-child(2n) {
      border-top: 1px solid #ccd2d4;
    }

    &:nth-child(2n + 1) {
      border-top: 1px solid #ccd2d4;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .rows.limits,
  .rows.transactions {
    &:nth-child(2n) {
      border-top: none;
    }

    &:nth-child(2n + 1) {
      border-top: none;
    }
  }

  .rows.header.history {
    display: grid;
    grid-auto-columns: 1fr;
    column-gap: 30px;
    row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    grid-template-rows: auto;
  }
  .rows.history {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    padding: 0;
    align-items: center;
    align-content: center;

    .placeholder {
      display: none;
    }

    &:nth-child(2n) {
      border-top: none;
    }

    &:nth-child(2n + 1) {
      border-top: none;
    }
  }

  .rows.header,
  .transactions-header {
    display: none;
  }

  .rows.header-limits {
    display: none;
  }
  .rows.limits {
    height: auto;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 992px) {
  .w-inline-block-button {
    margin: 0 auto !important;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .table-filters {
    row-gap: 15px;
    grid-template-columns: 1fr;
  }

  .pagination-wrapper {
    margin-top: 15px;
  }

  .grid-rows.history {
    grid-template-columns: 1fr 1fr;
    margin-top: -20px;
  }

  .rows {
    padding: 20px 0 20px;
  }

  .rows.header.history {
    display: none;
  }

  .rows.history {
    padding-top: 25px;
    padding-bottom: 25px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr !important;

    .placeholder {
      display: block;
    }
  }

  .close-modal {
    top: 18px;
    right: 25px;
    z-index: 25;
  }
}

@media screen and (max-width: 479px) {
  .pagination-table {
    padding: 20px;
  }

  .table-filters {
    grid-template-columns: 1fr;
  }

  .grid-rows {
    grid-template-columns: 1fr;
  }

  .grid-rows.limits {
    margin-top: 0;
  }

  .grid-rows.history {
    margin: 0 20px 20px;
    grid-template-columns: 1fr;
  }

  .rows {
    &:first-child {
      border-top: 1px solid #ccd2d4;
    }

    &:nth-child(2n) {
      border-top: none;
    }

    &:nth-child(2n + 1) {
      border-top: none;
    }
  }

  .pagination-wrapper {
    margin-top: 10px;
    justify-content: space-between;
  }

  .cta-button-block {
    display: block;
  }
}
