[class^="flag-"], [class*="flag-"] {
  background: url('/assets/images/authy-country-code-flags.png') no-repeat;
}

.aflag {
  width: 16px;
  height: 11px;
  display: inline-block;
}

.flag-af93 {
  background-position: -48px 0;
}

.flag-al213 {
  background-position: -160px -33px;
}

.flag-al355 {
  background-position: -96px 0;
}

.flag-an1264 {
  background-position: -80px 0;
}

.flag-an1268 {
  background-position: -64px 0;
}

.flag-an244 {
  background-position: -144px 0;
}

.flag-an376 {
  background-position: -16px 0;
}

.flag-ar297 {
  background-position: -224px 0;
}

.flag-ar374 {
  background-position: -112px 0;
}

.flag-ar54 {
  background-position: -160px 0;
}

.flag-au43 {
  background-position: -192px 0;
}

.flag-au61 {
  background-position: -208px 0;
}

.flag-az994 {
  background-position: -240px 0;
}

.flag-ba1242 {
  background-position: -208px -11px;
}

.flag-ba1246 {
  background-position: -16px -11px;
}

.flag-ba880 {
  background-position: -32px -11px;
}

.flag-ba973 {
  background-position: -96px -11px;
}

.flag-be1441 {
  background-position: -144px -11px;
}

.flag-be229 {
  background-position: -128px -11px;
}

.flag-be32 {
  background-position: -48px -11px;
}

.flag-be375 {
  background-position: -16px -22px;
}

.flag-be501 {
  background-position: -32px -22px;
}

.flag-bh975 {
  background-position: -224px -11px;
}

.flag-bo267 {
  background-position: 0 -22px;
}

.flag-bo387 {
  background-position: 0 -11px;
}

.flag-bo591 {
  background-position: -176px -11px;
}

.flag-br55 {
  background-position: -192px -11px;
}

.flag-br673 {
  background-position: -160px -11px;
}

.flag-bu226 {
  background-position: -64px -11px;
}

.flag-bu257 {
  background-position: -112px -11px;
}

.flag-bu359 {
  background-position: -80px -11px;
}

.flag-bv {
  background-position: -240px -11px;
}

.flag-ca1 {
  background-position: -48px -22px;
}

.flag-ca1345 {
  background-position: -192px -77px;
}

.flag-ca237 {
  background-position: -192px -22px;
}

.flag-ca238 {
  background-position: -16px -33px;
}

.flag-ca855 {
  background-position: -64px -77px;
}

.flag-catalonia {
  background-position: -64px -22px;
}

.flag-ce236 {
  background-position: -96px -22px;
}

.flag-ch235 {
  background-position: -112px -143px;
}

.flag-ch56 {
  background-position: -176px -22px;
}

.flag-ch86 {
  background-position: -208px -22px;
}

.flag-co2420 {
  background-position: -80px -22px;
}

.flag-co243 {
  background-position: -112px -22px;
}

.flag-co506 {
  background-position: -240px -22px;
}

.flag-co57 {
  background-position: -224px -22px;
}

.flag-co682 {
  background-position: -160px -22px;
}

.flag-cr385 {
  background-position: 0 -66px;
}

.flag-cu53 {
  background-position: 0 -33px;
}

.flag-cw {
  background-position: -32px -33px;
}

.flag-cy357 {
  background-position: -48px -33px;
}

.flag-cz420 {
  background-position: -64px -33px;
}

.flag-de45 {
  background-position: -112px -33px;
}

.flag-dj253 {
  background-position: -96px -33px;
}

.flag-do1767 {
  background-position: -128px -33px;
}

.flag-do1809, .flag-do1829, .flag-do1849 {
  background-position: -144px -33px;
}

.flag-ec593 {
  background-position: -176px -33px;
}

.flag-eg20 {
  background-position: -208px -33px;
}

.flag-eh {
  background-position: -224px -33px;
}

.flag-el503 {
  background-position: -32px -143px;
}

.flag-england {
  background-position: -240px -33px;
}

.flag-er {
  background-position: 0 -44px;
}

.flag-es372 {
  background-position: -192px -33px;
}

.flag-et251 {
  background-position: -32px -44px;
}

.flag-eu {
  background-position: -48px -44px;
}

.flag-fa298 {
  background-position: -128px -44px;
}

.flag-fa500 {
  background-position: -96px -44px;
}

.flag-fi358 {
  background-position: -64px -44px;
}

.flag-fi679 {
  background-position: -80px -44px;
}

.flag-fm {
  background-position: -112px -44px;
}

.flag-fr33 {
  background-position: -144px -44px;
}

.flag-fr594 {
  background-position: -224px -44px;
}

.flag-fr689 {
  background-position: -224px -110px;
}

.flag-ga220 {
  background-position: -48px -55px;
}

.flag-ga241 {
  background-position: -160px -44px;
}

.flag-ge49 {
  background-position: -80px -33px;
}

.flag-ge995 {
  background-position: -208px -44px;
}

.flag-gh233 {
  background-position: 0 -55px;
}

.flag-gi350 {
  background-position: -16px -55px;
}

.flag-gq {
  background-position: -96px -55px;
}

.flag-gr1473 {
  background-position: -192px -44px;
}

.flag-gr299 {
  background-position: -32px -55px;
}

.flag-gr30 {
  background-position: -112px -55px;
}

.flag-gs {
  background-position: -128px -55px;
}

.flag-gu224 {
  background-position: -64px -55px;
}

.flag-gu245 {
  background-position: -176px -55px;
}

.flag-gu44 {
  background-position: -240px -44px;
}

.flag-gu502 {
  background-position: -144px -55px;
}

.flag-gu590 {
  background-position: -80px -55px;
}

.flag-gu592 {
  background-position: -192px -55px;
}

.flag-gu671 {
  background-position: -160px -55px;
}

.flag-ha509 {
  background-position: -16px -66px;
}

.flag-hm {
  background-position: -224px -55px;
}

.flag-ho504 {
  background-position: -240px -55px;
}

.flag-ho852 {
  background-position: -208px -55px;
}

.flag-hu36 {
  background-position: -32px -66px;
}

.flag-ic {
  background-position: -48px -66px;
}

.flag-ic354 {
  background-position: -192px -66px;
}

.flag-in62 {
  background-position: -64px -66px;
}

.flag-in91 {
  background-position: -128px -66px;
}

.flag-io {
  background-position: -144px -66px;
}

.flag-ir353 {
  background-position: -80px -66px;
}

.flag-ir964 {
  background-position: -160px -66px;
}

.flag-ir98 {
  background-position: -176px -66px;
}

.flag-is44 {
  background-position: -112px -66px;
}

.flag-is972 {
  background-position: -96px -66px;
}

.flag-it39 {
  background-position: -208px -66px;
}

.flag-iv225 {
  background-position: -144px -22px;
}

.flag-ja1876 {
  background-position: -240px -66px;
}

.flag-ja81 {
  background-position: -16px -77px;
}

.flag-je44 {
  background-position: -224px -66px;
}

.flag-jo962 {
  background-position: 0 -77px;
}

.flag-ka7 {
  background-position: -208px -77px;
}

.flag-ke254 {
  background-position: -32px -77px;
}

.flag-ki {
  background-position: -80px -77px;
}

.flag-km {
  background-position: -96px -77px;
}

.flag-ko82 {
  background-position: -144px -77px;
}

.flag-ko850 {
  background-position: -128px -77px;
}

.flag-ku965 {
  background-position: -176px -77px;
}

.flag-kurdistan {
  background-position: -160px -77px;
}

.flag-ky996 {
  background-position: -48px -77px;
}

.flag-la371 {
  background-position: -112px -88px;
}

.flag-la856 {
  background-position: -224px -77px;
}

.flag-le266 {
  background-position: -64px -88px;
}

.flag-le961 {
  background-position: -240px -77px;
}

.flag-li218 {
  background-position: -128px -88px;
}

.flag-li231 {
  background-position: -48px -88px;
}

.flag-li370 {
  background-position: -80px -88px;
}

.flag-li423 {
  background-position: -16px -88px;
}

.flag-lu352 {
  background-position: -96px -88px;
}

.flag-ma222 {
  background-position: -96px -99px;
}

.flag-ma223 {
  background-position: 0 -99px;
}

.flag-ma230 {
  background-position: -144px -99px;
}

.flag-ma261 {
  background-position: -208px -88px;
}

.flag-ma265 {
  background-position: -176px -99px;
}

.flag-ma269 {
  background-position: -112px -165px;
}

.flag-ma356 {
  background-position: -128px -99px;
}

.flag-ma389 {
  background-position: -240px -88px;
}

.flag-ma596 {
  background-position: -80px -99px;
}

.flag-ma60 {
  background-position: -208px -99px;
}

.flag-ma853 {
  background-position: -48px -99px;
}

.flag-ma960 {
  background-position: -160px -99px;
}

.flag-me52 {
  background-position: -192px -99px;
}

.flag-mh {
  background-position: -224px -88px;
}

.flag-mo1664 {
  background-position: -112px -99px;
}

.flag-mo212 {
  background-position: -144px -88px;
}

.flag-mo258 {
  background-position: -224px -99px;
}

.flag-mo373 {
  background-position: -176px -88px;
}

.flag-mo377 {
  background-position: -160px -88px;
}

.flag-mo382 {
  background-position: -192px -88px;
}

.flag-mo976 {
  background-position: -32px -99px;
}

.flag-mp {
  background-position: -64px -99px;
}

.flag-my95 {
  background-position: -16px -99px;
}

.flag-na264 {
  background-position: -240px -99px;
}

.flag-na674 {
  background-position: -128px -110px;
}

.flag-ne31 {
  background-position: -80px -110px;
}

.flag-ne599 {
  background-position: -128px 0;
}

.flag-ne64 {
  background-position: -160px -110px;
}

.flag-ne687 {
  background-position: 0 -110px;
}

.flag-ne977 {
  background-position: -112px -110px;
}

.flag-nf {
  background-position: -32px -110px;
}

.flag-ni227 {
  background-position: -16px -110px;
}

.flag-ni234 {
  background-position: -48px -110px;
}

.flag-ni505 {
  background-position: -64px -110px;
}

.flag-no47 {
  background-position: -96px -110px;
}

.flag-nu {
  background-position: -144px -110px;
}

.flag-om968 {
  background-position: -176px -110px;
}

.flag-pa507 {
  background-position: -192px -110px;
}

.flag-pa595 {
  background-position: -144px -121px;
}

.flag-pa675 {
  background-position: -240px -110px;
}

.flag-pa92 {
  background-position: -16px -121px;
}

.flag-pa970 {
  background-position: -96px -121px;
}

.flag-pe51 {
  background-position: -208px -110px;
}

.flag-ph63 {
  background-position: 0 -121px;
}

.flag-pm {
  background-position: -48px -121px;
}

.flag-pn {
  background-position: -64px -121px;
}

.flag-po351 {
  background-position: -112px -121px;
}

.flag-po48 {
  background-position: -32px -121px;
}

.flag-pu1787 {
  background-position: -80px -121px;
}

.flag-pw {
  background-position: -128px -121px;
}

.flag-qa974 {
  background-position: -160px -121px;
}

.flag-re262 {
  background-position: -176px -121px;
}

.flag-ro40 {
  background-position: -192px -121px;
}

.flag-ru7 {
  background-position: -224px -121px;
}

.flag-rw250 {
  background-position: -240px -121px;
}

.flag-sa1758 {
  background-position: 0 -88px;
}

.flag-sa1869 {
  background-position: -112px -77px;
}

.flag-sa239 {
  background-position: -16px -143px;
}

.flag-sa378 {
  background-position: -176px -132px;
}

.flag-sa685 {
  background-position: -176px 0;
  background-position: -80px -165px;
}

.flag-sa966 {
  background-position: 0 -132px;
}

.flag-scotland {
  background-position: -48px -132px;
}

.flag-se221 {
  background-position: -192px -132px;
}

.flag-se248 {
  background-position: -32px -132px;
}

.flag-se381 {
  background-position: -208px -121px;
}

.flag-sh {
  background-position: -112px -132px;
}

.flag-si232 {
  background-position: -160px -132px;
}

.flag-si65 {
  background-position: -96px -132px;
}

.flag-sl386 {
  background-position: -128px -132px;
}

.flag-sl421 {
  background-position: -144px -132px;
}

.flag-so252 {
  background-position: -208px -132px;
}

.flag-so27 {
  background-position: -128px -165px;
}

.flag-so677 {
  background-position: -16px -132px;
}

.flag-somaliland {
  background-position: -224px -132px;
}

.flag-sp34 {
  background-position: -16px -44px;
}

.flag-sr94 {
  background-position: -32px -88px;
}

.flag-ss {
  background-position: 0 -143px;
}

.flag-st1784 {
  background-position: -208px -154px;
}

.flag-su249 {
  background-position: -64px -132px;
}

.flag-su597 {
  background-position: -240px -132px;
}

.flag-sw268 {
  background-position: -80px -143px;
}

.flag-sw41 {
  background-position: -128px -22px;
}

.flag-sw46 {
  background-position: -80px -132px;
}

.flag-sx {
  background-position: -48px -143px;
}

.flag-sy963 {
  background-position: -64px -143px;
}

.flag-ta255 {
  background-position: -80px -154px;
}

.flag-ta886 {
  background-position: -64px -154px;
}

.flag-ta992 {
  background-position: -176px -143px;
}

.flag-tf {
  background-position: -128px -143px;
}

.flag-th66 {
  background-position: -160px -143px;
}

.flag-ti670 {
  background-position: -208px -143px;
}

.flag-tk {
  background-position: -192px -143px;
}

.flag-to228 {
  background-position: -144px -143px;
}

.flag-to676 {
  background-position: 0 -154px;
}

.flag-tr1868 {
  background-position: -32px -154px;
}

.flag-tu1649 {
  background-position: -96px -143px;
}

.flag-tu216 {
  background-position: -240px -143px;
}

.flag-tu90 {
  background-position: -16px -154px;
}

.flag-tu993 {
  background-position: -224px -143px;
}

.flag-tv {
  background-position: -48px -154px;
}

.flag-ug256 {
  background-position: -112px -154px;
}

.flag-uk380 {
  background-position: -96px -154px;
}

.flag-um {
  background-position: -128px -154px;
}

.flag-un1 {
  background-position: -144px -154px;
}

.flag-un44 {
  background-position: -176px -44px;
}

.flag-un971 {
  background-position: -32px 0;
}

.flag-ur598 {
  background-position: -160px -154px;
}

.flag-uz998 {
  background-position: -176px -154px;
}

.flag-va {
  background-position: -192px -154px;
}

.flag-va678 {
  background-position: -32px -165px;
}

.flag-ve58 {
  background-position: -224px -154px;
}

.flag-vi {
  background-position: 0 -165px;
}

.flag-vi1284 {
  background-position: -240px -154px;
}

.flag-vi84 {
  background-position: -16px -165px;
}

.flag-wales {
  background-position: -48px -165px;
}

.flag-wf {
  background-position: -64px -165px;
}

.flag-ye967 {
  background-position: -96px -165px;
}

.flag-za260 {
  background-position: -160px -165px;
}

.flag-zanzibar {
  background-position: -144px -165px;
}

.flag-zi263 {
  background-position: -176px -165px;
}

.countries-autocomplete {
  background-color: #fff;
  border: 1px solid #cccccc;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    padding: 5px 3px;
    font-family: arial;
    text-align: left;
    span {
      margin: 0 2px;
    }
    &:hover, &.active {
      cursor: pointer;
      background-color: #eeeeee;
    }
  }
}

#authy-token {
  padding: 5px 0 5px 25px;
  background: url('/assets/images/authy-token-icon.png') no-repeat 2px center;
  background-size: 18px 18px;
}

.countries-autocomplete {
  position: absolute;
  width: 200px;
  display: none;
  max-height: 200px;
  overflow: auto;
  z-index: 99999999;
}