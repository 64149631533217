.ss-div {
  background: #fff;
  padding: 0px 40px;
  box-shadow: 0px 20px 20px rgb(194, 29, 51);
  padding: 0 40px;
}

.ss-ctn {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 28px 0;
  font-family: 'Poppins', sans-serif;
}

.ss-logo {
  // width: 304.59px;
  height: 75px;

  img {
    width: 100%;
    height: 100%;
  }
}

.ss-links {
  width: 50%;
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  align-items: center;
  justify-content: flex-end;
}

.ss-links ul {
  display: flex;
  width: 60%;
  padding: 5px;
  font-size: 16px;
  margin-top: 10px;
  margin-right: 20px;

  li {
    list-style: none;
    margin-left: 20px;
    margin-right: 20px;
  }

  li a {
    color: #2a2a2a;
  }

  li a:hover {
    text-decoration: none;
    color: #0a9ba9;
  }
}

.ss-btn {
  padding: 12px 24px;
  border-radius: 10px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  &.red {
    border: 1px solid rgb(237, 28, 36);
    background-color: rgb(237, 28, 36);
    &:hover {
      background-color: #c61017;
    }
    &:disabled,
    [disabled] {
      background-color: #b2b2af;
      border: 1px solid #b2b2af;
    }
  }
  &.dark-teal {
    height: 48px;
    background-color: rgb(10, 155, 169);
    border: 1px solid rgb(10, 155, 169);
    transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
    &:hover {
      background-color: #00788f;
    }
  }
}

.ss-burger {
  border: 2px solid #e4e6ea;
  height: 50px;
  width: 50px;
  text-align: center;
  margin-left: auto;
  padding: 12px;
  cursor: pointer;
  display: none;
}

.login-form {
  background: #eeeff0;
  margin: auto auto;
  width: 448px;
  height: 455px;
  border-radius: 15px;
  font-family: Poppins, sans-serif;
}

.new-header {
  font-family: 'Fredoka One', sans-serif;
  color: #3c3c3b !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 1;
}

.new-header-2 {
  font-family: 'Fredoka One', sans-serif;
  color: rgb(10, 155, 169) !important;
  font-weight: 400;
  font-size: 35px;
  line-height: 100%;
  margin-bottom: -10px;
}

.fixed-bg {
  background-color: rgb(227, 5, 18);
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(227, 5, 18, 0.6)),
      to(rgba(227, 5, 18, 0.6))
    ),
    url('../images/superswift/Pattern-1.svg');
  background-image: linear-gradient(180deg, rgba(227, 5, 18, 0.6), rgba(227, 5, 18, 0.6)),
    url('../images/superswift/Pattern-1.svg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, 850px;
  background-attachment: scroll, fixed;
  min-height: 86vh;
  padding-top: 6rem;
  padding-bottom: 6rem;
  align-items: center;
}

.ss-phone {
  border-radius: 10px;
  margin-right: 7px;
  border: 1px solid #e1e1e1;
  outline: none;

  &:focus {
    border: 4px solid #afccfa;
  }
}

.login-form input,
.reg-frame input {
  border-radius: 10px;
  padding-top: 10px;
  font: normal normal normal 16px/24px 'Poppins' !important;

  &::placeholder {
    color: #f0f2f5;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    opacity: 0.6;
  }
}

.reset-pwd {
  margin-top: -10px;
  margin-bottom: 30px;

  a {
    color: #b2b2af;
    font-family: Poppins, sans-serif;
    font-size: 16px;
  }

  a:hover {
    color: #3c3c3b;
    text-decoration: none;
  }
}

.new-acct {
  padding-bottom: 0px;

  color: #666664;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;

  a {
    color: #666664;
    cursor: pointer;
  }

  a:hover {
    color: #0a9ba9;
    text-decoration: none;
  }
}

.new-color {
  color: #666664 !important;
}

#ss-margin-left {
  margin-left: auto;
}

#margin-up {
  margin-top: -10px;
}

@media only screen and (max-width: 930px) {
  .ss-burger {
    display: inline-block;
    margin-bottom: 10px;
  }

  #ss-margin-left {
    margin-bottom: 30px;
  }

  .ss-links ul {
    flex-direction: column;
    width: 100%;
  }

  .ss-links ul li {
    width: 100%;
    margin: auto;
    text-align: center;
    a {
      display: block;
    }
  }

  .ss-btn {
    margin-top: 20px;
    margin-right: 17px;
  }

  .ss-div {
    position: relative;
  }

  .ss-links {
    position: absolute;
    width: 100%;
    display: flex;
    min-height: 152px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 130px;
    right: -110%;
    background: #e4e6ea;
    padding-bottom: 52px;
    padding-top: 52px;
    z-index: 1;
    transition: 0.8s all;
    &.register-transform {
      top: 179px !important;
      right: -10px !important;
      transition: transform 400ms ease !important;
      transform: translateY(-100%);
      overflow: hidden;
    }
    &.active {
      right: -10px;
    }
  }
  .topbar {
    display: none;
  }
  .register-transform.show {
    transform: translateY(0) !important;
    box-shadow: 0 4px 16px 0 rgb(0, 0, 0 / 0.25);
  }
  .ss-dark {
    background: #e4e6ea;
  }
  .flex-row-middle {
    max-width: 90% !important;
  }
}
.nav-overlay {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
  visibility: hidden;
  transition-duration: 0.4s;
}
.nav-overlay.show {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  top: 0;
  height: 430px;
}

.spikelet {
  display: flex;
  min-height: 86vh;
  padding-top: 2rem;
  padding-bottom: 6rem;
  align-items: center;
  width: 100%;
  z-index: auto;
  left: calc(-50vw + 50%);
}
.pattern-reg-1 {
  background-color: #0a9ba9;
  background-image: url('../images/spikelet.png');
  background-size: auto, 850px;
  background-attachment: fixed;
}
.inner-shadows {
  box-shadow: inset 0px 40px 40px -40px #2b2b2b80, inset 0px -40px 40px -40px #2b2b2b80;
}
.container-1200 {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.reg-frame {
  display: flex;
  width: 34rem;
  max-width: 34rem;
  padding: 32px 32px 30px;
  flex-direction: column;
  align-items: stretch;
  border-radius: 15px;
  background-color: #eeeff0;
  .form-control.w-input {
    background: #fff;
  }
  label {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #666664;
  }
  .control-label {
    margin-top: .5rem;
  }
}

.popins-14 {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.popins-16 {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  &.dim-grey {
    color: #666664;
  }
  &.placeholder {
    color: #b2b2af;
  }
  &.registration-placeholder {
    color: #b2b2af;
  }
  &.body-copy {
    color: #2a2a2a;
  }
  + li,
  + li + li,
  + li + li + li {
    color: #b2b2af;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }
}
/* SuperSwift Checkbox */
.ss-theme-checkbox {
  font-family: 'Fredoka One', sans-serif !important;
  color: #3c3c3b !important;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 1;
}
.alert-frame {
  display: flex;
  width: 34rem;
  max-width: 34rem;
  padding: 15px 32px 25px 15px;
  border-radius: 15px;
  align-items: stretch;
  background-color: #d4edda;
}

@media screen and (min-width: 930px) {
  .nav-overlay.show {
    visibility: hidden;
    opacity: 0;
  }
}
.w-nav {
  position: relative;
  z-index: 1000;
  overflow: hidden; // prevent horizontal scroll bar
}
@media screen and (max-width: 767px) {
  .reg-frame,
  .otp-foot,
  .alert-frame {
    max-width: 28rem !important;
  }
  .container-1200.flex-align-center {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media screen and (max-width: 479px) {
  .reg-frame,
  .otp-foot,
  .alert-frame {
    max-width: 25rem !important;
  }
  .container-1200.flex-align-center {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .container-1200.flex-row-middle {
    padding-right: 0;
    padding-left: 0;
  }
  .ss-logo {
    img {
      width: 80%;
    }
  }
  .fixed-bg,
  .spikelet {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}
