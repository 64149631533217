.label {
  color: #333;
  font-size: 0.9rem;
  font-weight: 700;
  margin: 24px 0px;
  text-transform: uppercase;
  line-height: 1.6rem;
}

.error {
  margin-bottom: 14px;
  color: #e60510;
  font-weight: 400;
}
