& {
  .scroll-tabs-container {
    height: 2.85714rem;
    left: 0;
    margin-bottom: 0.71429rem;
    position: relative;
    right: 0;
    text-align: left;
    top: 0;
    div.scroll-tab-inner {
      height: 2.85714rem;
      font-size: 0rem;
      span {
        cursor: pointer;
        font-size: 1rem;
        &.scroll-tab-left-finisher,
        &.scroll-tab-right-finisher {
          display: none !important;
        }
        > a {
          padding-left: 1.42857rem;
          padding-right: 1.42857rem;
          display: inline-block;
        }
      }
    }
    .scroll-tab-left-button {
      -moz-user-select: none;
      background-color: #cccccc;
      height: 2.85714rem;
      font-family: fontawesome;
      background-color: #dedede;
      color: #fff;
      height: 2rem;
      &::before {
        font-size: 1.1rem;
        line-height: 2rem;
      }
    }
    .scroll-tab-right-button {
      font-family: fontawesome;
      font-size: 1.1rem;
      line-height: 2rem;
      background-color: #dedede;
      color: #fff;
      height: 2rem;
    }
    .scroll-tab-right-button-over {
      background-color: #999999;
    }
    .scroll-tab-right-button-disabled {
      background-color: #cccccc;
      color: #aaaaaa;
    }
  }
  .scroll-tabs-container {
    border-bottom: 1px solid #eee;
    height: 7.07143rem;
    margin-bottom: 0;
    a {
      border-bottom: 3px solid transparent;
      color: #a1a1a1;
      font-size: 1.2857rem;
      max-height: 7.07143rem;
      padding: 2.1rem 1.6667rem 1.9rem;
    }
    &.pills-container {
      background: #f9f9f9 none repeat scroll 0 0;
      font-size: 1.2857rem;
      height: 5.6rem;
      margin-top: 0;
      margin-bottom: 20px;
      div.scroll-tab-inner span {
        background-color: #f2f2f2;
        border: 0.0714rem solid $scroll-tab-inner;
        border-radius: 1.428rem;
        line-height: 5.3rem;
        margin: 1.2rem 0.7142rem 0.7142rem;
        padding: 0.742rem 0;
        a {
          display: inline;
          font-size: 1.5rem;
          border-bottom-color: transparent;
          text-decoration: none;
        }
        &:hover,
        &:focus,
        &.selected {
          background-color: #f5f5f5;
          border: 0.0714rem solid $scroll-tab-inner;
          color: #851817;
          border-radius: 1.428rem;
        }
      }
      .scroll-tab-left-button,
      .scroll-tab-right-button {
        margin-top: 1.3rem;
      }
    }
  }
  .scroll-tabs-container .scroll-tab-left-button.scroll-arrow-disabled,
  body .scroll-tabs-container .scroll-tab-right-button.scroll-arrow-disabled {
    background-color: #ebebeb;
  }
}
