.page-title.white {
  color: #fff !important;
  font-weight: 400 !important;
  font-family: 'Fredoka One', sans-serif;
  font-size: 50px;
  width: fit-content;
  margin-bottom: 50px;
  align-self: flex-start;

  @media screen and (max-width: 479px) {
    font-size: 40px;
    align-self: center;
  }
}

.bar-1 {
  position: relative;
  z-index: 1;
  margin-left: -20px;
  padding-right: 20px;
  padding-left: 20px;
  border-top: 10px solid #0a9ba9;
  border-bottom: 10px solid #0a9ba9;
  background-color: #0a9ba9;
}

.accounts-card {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 15px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #ccd2d4 !important;
  padding: 30px !important;
  background-color: #fff;
  align-self: stretch;
  align-items: stretch;

  input {
    border-radius: 10px !important;
  }

  h2 {
    font-family: 'Fredoka One', sans-serif;
    font-size: 24px;
    color: #0a9ba9;
    font-weight: 400;
    padding-bottom: 20px;
  }
}

.checkbox-text {
  margin-top: 10px;
}

.w-inline-block-button {
  padding-right: 18px !important;
  padding-left: 18px !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-color: #ed1c24 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: normal !important;

  &:hover {
    background-color: #ed1c24 !important;
    color: #fff !important;
  }
}

.not-submitting {
  background-color: transparent !important;
  color: #2a2a2a !important;
}

.is-submitting {
  background-color: #ed1c24 !important;
  color: #fff !important;
}


@media screen and (max-width: 730px) {
  .checkbox-text {
    margin-top: 2px;
  }
}

@media screen and (max-width: 479px) {
  .accounts-card {
    padding: 20px !important;
  }
}
