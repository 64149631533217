.valid {
  background: rgb(164, 207, 164);
}

:is(.valid, .danger) {
  display: flex;
  align-items: center;
  img {
    height: 17px;
    width: 17px;
    margin-right: 10px;
  }
}

.danger {
  color: white;
  background: #eb4a4a;
}

.green {
  color: #0a9ba9;
}

.red {
  color: #ed1c24;
}

.footer {
  font-size: 14px;
}

:is(.debitConfirmed, .debitFailed) {
  background: #e2e3e5;
  padding: 12px 0;
}

.disclaimer {
  width: 97.5%;
  padding: 10px 15px;
  border-radius: 5px;
  background: white;
  margin: 0 auto;
}

.refNumber {
  font-weight: 700;
  padding-left: 0;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.failedQuote {
  padding: 15px 25px;
  border-radius: 5px;
  background: white;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 9px;
  grid-template-areas: '. .';
}

.success {
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  background-color: #0a9ba9;
  color: #fff;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.failed {
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  background-color: #ed1c24;
  color: #fff;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.successContent {
  display: flex;
  margin: 20px 30px;
  align-items: center;
  font-size: 16px;

  img {
    margin-right: 10px;
  }
}

.confirmedModal {
  font-family: 'Poppins', sans-serif;
  .modal-dailog {
    max-width: none;
  }
}

.confirmationContent {
  display: flex;
  overflow: hidden;
  max-width: 705px;
  flex-direction: column;
  justify-content: flex-start;
  color: #666664;

  .heading.confirmation-header {
    margin-top: 30px;
    margin-bottom: 0px;
    align-self: flex-start;
    color: #3c3c3b;
    font-size: 24px;
    line-height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .successContent {
    align-items: flex-start;

    img {
      margin-top: 4px;
    }
  }
}

@media screen and (max-width: 467px) {
  .grid {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    p {
      margin-bottom: 0;
    }
  }
}
.ozow {
  height: 900px;
}
