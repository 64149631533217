.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 13px;
  color: #161c29;
  margin-bottom: 16px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxButtonField {
  display: flex;
  padding-left: 22px !important;
  align-items: flex-start;
  margin: 15px 0 0;
  font-size: 16px;
  color: #666664;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #ccc;
  width: 24px;
  height: 24px;
  background-color: #fff;
}

.checkmark.newCheckmark {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  flex: 0 0 auto;
  border: 1px solid #ccd2d4;
  border-radius: 5px;
  background-color: #eeeff080;
  background-size: 15px;
  transition: all 200ms ease;
  color: #fff;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  border: 1px solid #7c8391;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #e60510;
  border: 1px solid #e60510;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 2.5px;
  width: 8px;
  height: 13px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@supports (font-family: 'Fredoka One') {
  .checkmark {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccd2d4;
    + span {
      margin-left: 0.5rem;
      display: inline-block;
    }
  }
  .container input:checked ~ .checkmark {
    background-color: #ed1c24;
    border: 1px solid #c61017;
  }
  .container .checkmark:after {
    left: 10px;
    top: 5px;
    width: 8px;
    height: 13px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .container .checkmark.newCheckmark:after {
    left: 5px;
    top: 0;
    width: 8px;
    height: 13px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
