.footer {
  margin-bottom: -40px;
  padding-top: 4rem;
  padding-bottom: 1.5rem;
  background-color: #2e333b;
  font-family: Poppins, sans-serif;
}

.footer-columns-container {
  display: grid;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 1.5rem;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  grid-template-rows: auto;
}

.container-1200 {
  max-width: 1200px;
}

.container-1200.footer-columns {
  display: grid;
  margin-bottom: 64px;
  justify-content: space-between;
  align-items: flex-start;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  color: #ccd2d4;
}

.container-1200.footer-companies {
  margin-bottom: 1.5rem;
  width: 90%;
  max-width: 1160px;
}

.heading-5 {
  font-family: 'Fredoka One', sans-serif;
  color: #2e333b;
  font-weight: 400;
}

.text-base {
  color: #ccd2d4;
  font-size: 16px;
}

.text-base.footer-link {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  color: #ccd2d4 !important;
}

.text-base.footer-link:hover {
  color: #fff !important;
  text-decoration: none;
}

.text-base.social-text {
  display: inline-block;
  color: #c7ccd4;
  font-size: 16px;
}

.heading-5.neutral-0 {
  color: #fff;
  font-size: 20px;
}

.social-links {
  margin-right: 0.75rem;
  opacity: 0.8;
}

.social-links:hover {
  opacity: 1;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.w-container {
  margin: 0 auto;
}

.footer-shoprite-group {
  display: flex;
  padding: 16px 0;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #444c58;
  border-bottom: 1px solid #444c58;
}

.footer-shoprite-group-item {
  opacity: 0.8;
}

.footer-shoprite-group-item:hover {
  opacity: 1;
}

.container-1200.footer-legal {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-link {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  text-decoration: none;
  font-size: 16px;
}

.footer-legal-link {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  color: #8e98a7;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
}

.footer-legal-link:hover {
  color: #c7ccd4;
  text-decoration: none;
}

.footer-legal-link:focus {
  color: #c7ccd4;
}

.footer-copyright {
  color: #8e98a7;
  font-size: 12px;
}

.container-1200.footer-copyright {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 910px) {
  .footer-shoprite-group {
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: center;
  }

  .footer-shoprite-group-item {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 890px) {
  .footer-shoprite-group {
    justify-content: center;
  }

  .footer-shoprite-group-item {
    margin-top: 15px;
  }
}
