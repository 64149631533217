.chfx {
  $navbar-inverse-bg: #45484d;
  $navbar-inverse-link-color: #707070;
  $navbar-inverse-link-active-bg: #851718;
  $navbar-inverse-link-transform: capitalize;
  $navbar-font-family: 'Open Sans';
  $navbar-font-weight: 600;
  $nav-link-padding: 20px;
  $navbar-font-size: 13px;
  $navbar-inverse-link-active-color: #f0f0f0;
  $link-color: #851718;
  $link-hover-color: #851718;
  $footer-bg: #45484d;
  $footer-color: #fff;
  $btn-primary: #851718;
  $btn-primary-border: #851718;
  $btn-theme: #fff;
  $btn-default: #851718;
  $btn-success: #851718;
  $btn-success-border: #851718;
  $sub-navbar-bg: #fff;
  $subnav-link-color: #7d7d7d;
  $subnav-link-active-bg: transparent;
  $subnav-link-active-color: #fff;
  $subnav-padding: 14px 18px;
  $subnav-font-family: 'Montserrat';
  $subnav-font-size: 12px;
  $checkbox-success: #851718;
  $checkbox-success-border: #851718;
  $checkbox-primary: #851718;
  $checkbox-primary-border: #851718;
  $checkbox-warning: #851718;
  $checkbox-warning-border: #851718;
  $checkbox-danger: #e60106;
  $checkbox-danger-border: #e60106;
  @import '~assets/styles/layout';
}