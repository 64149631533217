@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";


nav .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.shoprite-toggler .navbar-toggler {
  border-color: $danger;
  background: $danger;
}

