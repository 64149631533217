@import 'variables';
@import 'scrolltabs';

& {
  header {
    min-height: 85px;
  }

  font-family: 'Open Sans', sans-serif;
  nav.navbar-inverse {
    background-color: $navbar-inverse-bg;
    margin-bottom: 0;
    padding: 0rem 1rem !important;
    .nav.navbar-nav {
      width: 100%;
      li {
        span {
          color: $navbar-inverse-link-color;
          text-transform: $navbar-inverse-link-transform;
          font-family: $navbar-font-family;
          padding: $nav-link-padding;
          font-size: $navbar-font-size;
          font-weight: $navbar-font-weight;
        }
        a {
          color: $navbar-inverse-link-color;
          text-transform: $navbar-inverse-link-transform;
          font-family: $navbar-font-family;
          padding: $nav-link-padding;
          font-size: $navbar-font-size;
          font-weight: $navbar-font-weight;
          text-decoration: none;
          i {
            font-size: 28px;
            display: block;
            margin: 10px;
            &.icon-new-transfer {
              color: $icon-new-transfer;
            }
          }
          &:hover,
          &:focus,
          &:active,
          &.active {
            background-color: $navbar-inverse-link-active-bg;
            color: $navbar-inverse-link-active-color;
            i {
              color: $navbar-inverse-link-icon-hover-color;
            }
          }
        }
        position: static;
        &:active,
        &:hover,
        &:focus,
        &.active,
        &.selected {
          background-color: $sub-navbar-bg;
          color: $subnav-link-color;
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
    #sub-navbar {
      background-color: $sub-navbar-bg;
      top: 52px;
      border: none;
      border-radius: unset;
      width: 100%;
      ul li {
        a {
          color: $subnav-link-color;
          padding: $subnav-padding;
          text-transform: capitalize;
          font-family: $subnav-font-family;
          font-size: $subnav-font-size;
          font-weight: normal;
          &:hover,
          &:focus,
          &.active {
            background-color: $subnav-link-active-bg;
            color: $subnav-link-active-color;
          }
        }
      }
    }
  }
  a {
    color: $link-color;
    &:hover,
    &:active,
    &:focus {
      color: $link-hover-color;
    }
  }
  #top-bar a {
    font-family: Montserrat;
    font-size: 13px;
    color: #717171;
    span {
      color: #c1c1c1;
    }
  }
  .form-horizontal .control-label {
    font-weight: normal;
    padding-top: 12px;
    text-align: left;
  }
  .dropdown-menu {
    min-width: 149px;
  }
  .dropdown-toggle,
  select {
    &.form-control {
      background: #f5f5f5 url(/assets/images/up-down-arrow.png) no-repeat right center;
      padding-right: 1.57rem;
      padding-top: 0.57rem;
      color: #919191;
      outline: none;
      overflow: hidden;
      font-size: 14px;
      &:hover,
      &:active,
      &:focus {
        cursor: pointer;
      }
      &::-ms-expand {
        display: none;
      }
    }
  }

  .form-select > .select__control {
    border: 1px solid #e1e1e1;
    height: 46px;
    border-radius: 10px;
  }
  // ---- CSS For Select List with Image ------
  select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    color: #4f4f4f;
    option {
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
      padding: 10px 12px;
    }
    &,
    option {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.img-select {
      padding-left: 42px;
      max-width: 340px;
      option {
        max-width: 325px;
      }
    }
    &.ng-invalid {
      padding-left: 10px;
    }
  }
  .flagicon {
    margin-left: 11px;
    margin-top: -33px;
    position: absolute;
    width: 28px;
  }
  .dropdown-toggle-select-list {
    max-height: 385px;
    overflow: auto;
    padding: 10px 38px 18px;
  }
  .dropdown-toggle {
    &.dropdown-menu {
      margin: 40px 0 0;
      a {
        text-decoration: none;
      }
    }
  }
  .page-header {
    border-bottom: 2px solid #eee;
    margin-bottom: 45px;
    padding-bottom: 22px;
  }
  .btn {
    &.btn-primary,
    &.btn-success,
    &.btn-default {
      background-color: $btn-primary;
      border-color: $btn-primary-border;
      color: $btn-theme;
    }
    &.btn-info {
      background-color: #c1c1c1;
      border-color: #c1c1c1;
    }
    &.btn-muted {
      color: $text-muted;
      background-color: $btn-muted;
    }
    &.btn-default.btn-theme {
      color: $btn-theme;
      background-color: $btn-default;
      font-weight: normal;
      font-family: 'Montserrat';
      &.danger {
        color: #fff;
        background-color: #f44133;
        border-color: #f44133;
      }
    }
    &.btn-width-200 {
      min-width: 200px;
    }
  }
  .open,
  .open_positive,
  .suspended,
  .rejected,
  .pending,
  .cancelled,
  .confirmed,
  .hold,
  .text-danger {
    &:not(.no-status) {
      color: #f44133;
    }
    &.btn-default.btn-lg {
      background-color: #c0c0c0;
      border-color: #c0c0c0;
      color: #fff;
    }
    .text-success {
      color: #72bc93;
    }
    .text-muted {
      color: #fa4238;
    }
  }
  .approved,
  .paid,
  .released,
  .settled,
  .partially_settled,
  .text-success {
    color: #2ba660;
  }
  .file-Upload {
    position: relative;
    overflow: hidden;
    margin: 10px;
  }
  .file-Upload input.upload {
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .panel-body {
    border: 6px solid #f5f5f5;
    padding: 30px;
    label {
      font-weight: normal;
    }
  }
  .well.transfer {
    background: #fff8e9;
    border: none;
    margin-left: -4.5rem;
    margin-right: -4.5rem;
    margin-top: -3rem;
    border-radius: 0px;
    padding: 15px;
    .flash-message {
      margin-left: 2rem;
    }
  }
  .panel,
  .panel-default > .panel-heading {
    border: none !important;
  }
  .transfer-state-right {
    padding: 50px;
  }
  .currency-text {
    color: #ff8e2c;
  }
  .multi-bene-detail-tooltip {
    color: #e0e0e0;
  }
  .remove-option {
    font-style: italic;
    &:hover,
    &:focus {
      color: #c0c0c0;
    }
    i {
      font-size: 0.9rem;
      margin: 0.5rem;
    }
  }
  .account-details {
    width: 310px;
    border: 1px solid #e0e0e0;
    padding: 10px 20px;
  }
  .vertical-line {
    border-left: 2px solid #e0e0e0;
    height: 100px;
  }
  .order-rate {
    margin: 10px;
    padding-top: 5px;
  }
  .panel-footer {
    border: 1px solid #f5f5f5;
    label {
      font-weight: normal;
    }
    a {
      text-decoration: none;
    }
  }
  .panel-body.contract {
    background-color: #fff8e9;
  }
  .modal-content.trns-proc-popup.text-left img {
    margin-left: 30px;
  }

  .checkbox {
    display: flex;
    position: relative;
    margin-top: 10px;
  }
  .checkbox input[type='checkbox'] {
    position: relative;
  }
  .checkbox label {
    margin-left: 12px;
    line-height: 16px;
  }
  .checkbox input[type='checkbox'] {
    border-radius: 6px;
  }
  .checkbox input[type='checkbox']::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    left: -1px;
    top: 0;
    color: white;
    padding-top: 1px;
    padding-left: 1.5px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: white;
  }
  .checkbox input[type='checkbox']:checked::after {
    font-family: 'FontAwesome';
    content: '\f00c';
    line-height: 16px;
    background-color: $checkbox-success;
    border-color: $checkbox-success-border;
  }
  .checkbox input[type='checkbox']:disabled + label {
    opacity: 0.65;
  }
  .checkbox.checkbox-inline {
    margin-top: 0;
  }

  .radio {
    padding-left: 20px;
  }
  .radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
  }
  .radio label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
  }
  .radio label::after {
    display: inline-block;
    position: absolute;
    content: ' ';
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #555555;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  }
  .radio input[type='radio'] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
  .radio input[type='radio']:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  .radio input[type='radio']:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  .radio input[type='radio']:disabled {
    cursor: not-allowed;
  }
  .radio input[type='radio']:disabled + label {
    opacity: 0.65;
  }
  .radio input[type='radio']:disabled + label::before {
    cursor: not-allowed;
  }
  .radio.radio-inline {
    margin-top: 0;
  }

  .radio-primary input[type='radio'] + label::after {
    background-color: #337ab7;
  }
  .radio-primary input[type='radio']:checked + label::before {
    border-color: #337ab7;
  }
  .radio-primary input[type='radio']:checked + label::after {
    background-color: #337ab7;
  }

  .radio-danger input[type='radio'] + label::after {
    background-color: #d9534f;
  }
  .radio-danger input[type='radio']:checked + label::before {
    border-color: #d9534f;
  }
  .radio-danger input[type='radio']:checked + label::after {
    background-color: #d9534f;
  }

  .radio-info input[type='radio'] + label::after {
    background-color: #5bc0de;
  }
  .radio-info input[type='radio']:checked + label::before {
    border-color: #5bc0de;
  }
  .radio-info input[type='radio']:checked + label::after {
    background-color: #5bc0de;
  }

  .radio-warning input[type='radio'] + label::after {
    background-color: #f0ad4e;
  }
  .radio-warning input[type='radio']:checked + label::before {
    border-color: #f0ad4e;
  }
  .radio-warning input[type='radio']:checked + label::after {
    background-color: #f0ad4e;
  }

  .radio-success input[type='radio'] + label::after {
    background-color: #fff;
  }
  .radio-success input[type='radio']:checked + label::before {
    border-color: #1ea365;
  }
  .radio-success input[type='radio']:checked + label::after {
    background-color: #1ea365;
  }

  .striped-rows {
    .row {
      margin-left: -3.571rem;
      margin-right: -3.571rem;
      padding: 0.857rem 2.857rem;
      div {
        word-wrap: break-word;
      }
      &:nth-child(2n + 1) {
        background: #f5f5f5 none repeat scroll 0 0;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  .row.text:nth-child(2n) {
    background-color: #fff8e9;
    margin-bottom: -59px;
  }
  .payment:nth-child(2n) {
    background-color: #fff8e9;
  }
  .modal-dialog {
    position: relative;
    margin: 30px auto;
  }
  .modal-header {
    padding: 4rem 3.571rem 1.14286rem;
    border-bottom: 1px solid #ffffff;
  }
  .modal-body {
    padding: 2.086rem 3.571rem 3.571rem;
    position: relative;
  }
  .modal-footer {
    padding: 2.086rem 0.571rem 3.214rem;
    border-top: 1px solid #fff;
    text-align: left;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal-header .close {
    margin-top: -2px;
  }
  .modal .modal-content .modal-header .close {
    padding: 0.857143rem;
    position: absolute;
    right: 0;
    top: 0;
  }
  .modal-header .modal-body,
  .modal-footer {
    .btn.btn-sm {
      &.btn-primary,
      &.btn-success,
      &.btn-danger,
      &.btn-warning,
      &.btn-default,
      &.btn-info {
        padding: 0.34rem 1.071rem;
      }
    }
  }
  .alert-link.info {
    color: #ff8e2c;
    font-weight: 700;
  }
  .alert-link.danger {
    color: #fa4238;
    font-weight: 700;
  }
  .alert-link.success {
    color: #1ea365;
    font-weight: 700;
  }
  .alert {
    border-radius: 0px;
  }
  .alert-success .alert-link {
    color: #ffffff;
    &.alert-link {
      font-weight: normal;
      text-decoration: underline;
    }
  }
  .alert.alert-success {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .alert-success {
    background-color: #1ea365;
    border-color: #1ea365;
    color: #ffffff;
  }
  .btn.btn-lg {
    font-size: 15px;
    padding: 11px 15px;
    &.btn-default {
      border: 1px solid #e1e1e1;
      color: #919191;
    }
  }
  .btn.btn-lg.cancel {
    background-color: #c0c0c0;
    color: #fff;
  }
  .btn.btn-lg.remove {
    background-color: #ffffff;
    color: #fa4238;
  }
  #status {
    color: #919191;
  }
  .table.table-hover.infinite-scroller a {
    text-decoration: none;
  }
  .nav-tabs.nav-justified > li.active > a {
    border-top: 0;
    background-color: #e0e0e0;
    color: #505050;
  }
  .nav-tabs.nav-justified > li > a {
    border-top: 0;
    border-bottom: 0;
    background-color: #f5f5f5;
    border-radius: 0;
    color: #909090;
  }
  textarea {
    resize: vertical;
  }
  footer {
    background-color: $footer-bg;
    color: $footer-color;
  }
}
