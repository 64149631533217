.refund-header {
  padding-left: 10px;
  padding-bottom: 5px;
  font-weight: bolder;
  border-bottom: 1px solid #d3d2d2;
}

.size-bg {
  font-size: 20px;
  color: #717171;
}

.refund-text-line {
  padding: 15px 10px;
  .border-bottom {
    border-bottom: 1px solid #d3d2d2;
  }
}

.refund-btns {
  gap: 40px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
}
