.tdb {
  $navbar-inverse-bg: #0d0d0d;
  $navbar-inverse-link-color: rgb(204, 204, 204);
  $navbar-inverse-link-bg: #333;
  $navbar-inverse-link-active-bg: #4d4d4d;
  $navbar-inverse-link-transform: capitalize;
  $navbar-font-family: 'Open Sans';
  $navbar-font-weight: 600;
  $nav-link-padding: 18px;
  $navbar-font-size: 13px;
  $navbar-inverse-link-active-color: #ffffff;
  $link-color: #e91f29;
  $link-hover-color: #852234;
  $footer-bg: #0d0d0d;
  $footer-color: #fff;
  $btn-primary: #e60106;
  $btn-primary-border: transparent;
  $btn-theme: #fff;
  $btn-default: #e60106;
  $btn-success: #e60106;
  $btn-success-border: transparent;
  $sub-navbar-bg: #4d4d4d;
  $subnav-link-color: rgb(204, 204, 204);
  $subnav-link-active-bg: #0d0d0d;
  $subnav-link-active-color: #fff;
  $subnav-padding: 16.5px 18px;
  $subnav-font-family: 'Montserrat';
  $subnav-font-size: 13px;
  $checkbox-success: #e60106;
  $checkbox-success-border: transparent;
  $checkbox-primary: #e60106;
  $checkbox-primary-border: transparent;
  $checkbox-warning: #e60106;
  $checkbox-warning-border: transparent;
  $checkbox-danger: #c1c1c1;
  $checkbox-danger-border: transparent;
  @import '~assets/styles/layout.tdb';
}
/* SELECT INPUT */
.tdb-theme-select {
  .select__control {
    background: #fff;
    font-weight: 400;
    color: #333;
    border: 1px solid #b3b3b3;
    border-radius: 2px;
  }
  .select__indicator-separator {
    display: none;
  }
  .select__placeholder {
    color: #666;
  }
}

/* TEXT INPUT */
input {
  display: block;
  padding: 0.8rem 1.1rem;
  font-size: 0.9rem;
  font-weight: 600;
  // line-height: 2.4rem;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// .modal-content {
//   top: 10rem;
// }
