.centered {
  min-width: 484px;
  max-width: 484px;
}
.beneficiary-card {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 15px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #ccd2d4 !important;
  padding: 30px !important;
  background-color: #fff;
  align-self: stretch;
  align-items: stretch;
}

.step2 .step3 {
  display: none;
}

.show {
  display: flex;
  flex-direction: column;
}

.form-group-1 {
  margin-top: -15px;
}

.cta-button {
  display: flex !important;
  height: 50px !important;
  font-size: 16px !important;
  font-weight: normal !important;
  min-width: 100px;
  padding: 10px 20px !important;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 10px !important;
  background-color: #ed1c24;
  transition: all 200ms ease-in-out;
  color: #fff;

  &:hover {
    background-color: #c61017;
  }
}

@media (max-width: 768px) {
  .centered {
    min-width: auto;
  }
}

@media (max-width: 467px) {
  .centered {
    .page-title.white {
      font-size: 30px !important;
    }
  }
}
