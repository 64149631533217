.w-layout-grid.economics.w-row {
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  border: none !important;
  padding: 0;
}

.label {
  margin-bottom: 0px;
  color: #b2b2af;
}

.large {
  font-size: 26px;
  line-height: 24px;
}

.fx-label {
  color: #b2b2af;
}

@media screen and (max-width: 991px) {
  .economics-grid.split {
    grid-auto-columns: 1fr;
    column-gap: 30px !important;
    grid-template-columns: 1fr 1fr !important;
  }
}