.nameAndMethod {
	font-size: 12px;
	padding-left: 0;
	padding-top: 6px;
}

.fullName {
	font-size: 12px;
	text-transform: capitalize;
}

.codeAndRenumeration {
	font-size: 12px;
	padding-left: 10px;
}

.select-input {
  height: 50px !important;
  background-color: #eeeff080 !important;
  border: 1px solid #ccd2d4 !important;
  border-radius: 10px !important;
  width: 100% !important;
  font-size: 16px;
  color: #b2b2af !important;

  .select__control {
    background-color: transparent !important;
    border: none !important;
    height: -webkit-fill-available !important;

    .select__value-container {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      margin-right: 15px;

      .fullName {
        font-size: 16px;
      }
    }
    .select__value-container--has-value {
      padding: 2px 1.1rem;
    }
    .select__option {
      display: none;
    }
    .select__indicators {
      // display: none;
    }
  }

  .select__menu {
    .select__menu-list {
      max-height: 240px;
    }
    .select__value-container {
      display: none;
    }
  }
}

