.bene-modal.transaction {
  display: flex !important;
  place-content: center;
  height: -webkit-fill-available;
  padding-right: 0;

  .modal-dialog {
    max-width: none !important;
    display: flex;
    align-items: center;

    .modal-content {
      border-radius: 15px !important;
    }
  }
}

.content {
  margin: 30px;
  padding: 0 !important;
}

.processing {
  overflow: hidden;
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.modal-p {
  margin-top: 15px;
  margin-bottom: 0px;
  color: #666664;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 767px){
  .content {
    margin: 25px;
  }
}

@media screen and (max-width: 479px) {
  .content {
    margin: 20px;
  }
}