.fileinput {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;

  ::-webkit-file-upload-button {
    visibility: hidden;
  }
  input[type='file'] {
    width: 150px;
  }
  .ss-custom-input::before {
    content: 'Choose file';
    display: inline-block;
    background: #b2b2af 0% 0% no-repeat padding-box;
    border: 1px solid #b2b2af;
    border-radius: 10px;
    padding: 10px 40px 10px 10px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    text-align: left;
    color: #ffffff;
    font: normal normal normal 16px/24px 'Poppins';
    background-image: url(/assets/svg/upload.svg);
    background-repeat: no-repeat;
    background-size: 1rem 1rem !important;
    background-position: right 1rem center !important;
  }
  .ss-custom-input:active::before {
    background: #b2b2af66 0% 0% no-repeat padding-box;
    background-image: url(/assets/svg/upload_active.svg);
    background-repeat: no-repeat;
    background-size: 1rem 1rem !important;
    background-position: right 1rem center !important;
  }

  .uploaded::before {
    color: #b2b2af !important;
    background: #b2b2af66 0% 0% no-repeat padding-box;
    background-image: url(/assets/svg/upload_active.svg);
    background-repeat: no-repeat;
    background-size: 1rem 1rem !important;
    background-position: right 1rem center !important;
  }
  .is-valid {
    color: #0a9ba9 !important;
    font: normal normal normal 16px/24px 'Poppins';
  }
  .bottom-file-input-text {
    text-align: left;
    font: normal normal normal 14px/20px 'Poppins';
    color: #b2b2af;
  }
}
