.new-inner-header {
  font-family: 'Poppins', sans-serif;
  font-size: 16px !important;
  height: 100px;
  z-index: 5;
  a {
    color: #222222 !important;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #ed1c24 !important;
      text-decoration: none;
    }
  }

  .navbar {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    padding: 0rem 0rem;
  }
  .navigation-2 {
    width: 100%;
    height: 100px;
    padding-right: 50px;
    padding-left: 50px;
    background-color: #fff !important;
  }
  .navbar-content {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
  }
  .w-nav {
    position: relative;
    background: #dddddd;
    z-index: 1000;
  }
  .brand {
    margin-left: -5px;
  }
  .brand-logo {
    height: 75px;
  }
  .brand-logo.small {
    height: 65px;
  }
  .w-nav-brand {
    position: relative;
    float: left;
    text-decoration: none;
    color: #333333;
  }

  .nav-menu.full {
    display: flex;
    align-self: stretch;
  }
  .w-nav-menu {
    position: relative;
    float: right;
  }

  .nav-2-link.mobile {
    display: none;
  }
  .nav-2-link {
    display: flex;
    height: 50px;
    align-items: center;
    flex: 0 0 auto;
  }
  .nav-2-link.w--current {
    height: 100%;
    border-top: 3px solid #ed1c24;
    color: #ed1c24;
  }
  .cta-button.nav-2 {
    margin-right: 20px;
    margin-left: 20px;
    flex: 0 0 auto;
  }
  .w-nav-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #222222;
    height: 100%;
    padding: 35px 20px;
    border-top: 3px solid transparent;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
  .nav-block {
    display: flex;
    align-items: center;
  }
  .w-inline-block {
    max-width: 100%;
    display: inline-block;
  }
  .cta-button {
    background-image: url(/assets/svg/rocket_send.svg);
    background-repeat: no-repeat;
    background-size: 1.3rem 1.3rem;
    background-position: left 1rem center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-left: 25px;
    padding: 10px 20px;
    flex-wrap: nowrap;
    border-radius: 10px;
    background-color: #ed1c24;

    & a {
      padding-left: 25px;
      color: #fff !important;
    }

    &:hover,
    &:focus {
      background-color: #c61017;
    }
  }
  .dropdown-trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    flex-direction: row;
    align-self: center;
    flex: 0 0 auto;
    cursor: pointer;
    color: #2a2a2a !important;
  }
  .icon {
    margin-left: 10px;
    width: 10px;
    height: 10px;
    transition: transform 400ms ease !important;
  }
  .icon-left {
    margin-right: 10px;
  }
  .w-nav-button {
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
  }
  .subnav {
    overflow: hidden;
    width: 100%;
    z-index: 5;
    height: 50px;
    padding-right: 50px;
    padding-left: 50px;
    z-index: 5;
    background-color: #ed1c24;
    color: #fff;
    transition: transform 400ms ease !important;
    transform: translateY(-110%);
  }
  .show {
    transform: translateY(0);
  }
  .subnav-link {
    display: flex;
    padding-right: 20px;
    padding-left: 44px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    color: #fff !important;
    cursor: pointer;
    &:hover {
      background-color: #c61017;
      color: #fff !important;
    }
  }
  .subnav-container {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 0px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #fff !important;
    a {
      color: #fff !important;
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: left 1.5rem center !important;
      &:first-child {
        background-image: url(/assets/svg/my_profile.svg);
      }
      &:nth-child(2n) {
        background-image: url(/assets/svg/support.svg);
      }
      &:last-child {
        background-image: url(/assets/svg/Log_out.svg);
      }
    }
  }
  .nav-burger {
    display: none;
  }
  .mobile-nav-overlay {
    display: none;
  }
}

@media screen and (max-width: 1111px) and (min-width: 992px) {
  .username {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .new-inner-header {
    .subnav {
      display: none;
    }
    .nav-block {
      display: none;
    }

    .mobile-nav-overlay {
      z-index: 5;
      overflow: hidden;
      width: 100%;
      transition: transform 400ms ease !important;
      transform: translateY(-110%);
    }

    .show-mobile {
      transform: translateY(0);
      display: flex;
    }

    .subnav-container-mobile {
      display: flex;
      width: 100%;
      height: 384px;
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
      padding: 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #ed1c24;
      color: #fff !important;
      margin-top: -1.5rem;

      li {
        justify-content: center;
        cursor: pointer;
      }

      a {
        color: #fff !important;

        &:hover,
        &:focus,
        &:active,
        &.active {
          color: #c61017 !important;
          text-decoration: none;
        }
      }
    }

    .subnav-container-mobile.w-mobile {
      height: 310px;
    }

    .nav-burger {
      display: inline-block;
      align-self: center;
      cursor: pointer;

      i {
        padding: 15px;
      }
    }

    .cta-button.nav-2 {
      background-color: #3c3c3b;
      margin-top: 1rem;

      &:hover {
        background-color: #2a2a2a;
      }

      a {
        color: #fff !important;

        &:hover,
        &:focus,
        &:active,
        &.active {
          color: #fff !important;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .new-inner-header {
    .navigation-2 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
