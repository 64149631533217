.premierfx {
  $navbar-inverse-bg: #FFD700;
  $navbar-inverse-link-color: #353535;
  $navbar-inverse-link-active-bg: #f1f1f1;
  $navbar-inverse-link-transform: capitalize;
  $navbar-font-family: 'Open Sans';
  $navbar-font-weight: 600;
  $nav-link-padding: 20px;
  $navbar-font-size: 14px;
  $navbar-inverse-link-active-color: #FFD700;
  $link-color: #e5a208;
  $link-hover-color: #e5a208;
  $footer-bg: #f1f1f1;
  $footer-color: #353535;
  $btn-primary: #e5a208;
  $btn-primary-border: #e5a208;
  $btn-theme: #fff;
  $btn-default: #e5a208;
  $btn-success: #e5a208;
  $btn-success-border: #e5a208;
  $sub-navbar-bg: #f1f1f1;
  $subnav-link-color: #FFD700;
  $subnav-link-active-bg: transparent;
  $subnav-link-active-color: #fff;
  $subnav-padding: 14px 18px;
  $subnav-font-family: 'Montserrat';
  $subnav-font-size: 13px;
  $checkbox-success: #e5a208;
  $checkbox-success-border: #e5a208;
  $checkbox-primary: #e5a208;
  $checkbox-primary-border: #e5a208;
  $checkbox-warning: #f6be1a;
  $checkbox-warning-border: #f6be1a;
  $checkbox-danger: #f44336;
  $checkbox-danger-border: #f44336;
  @import '~assets/styles/layout';
}