.kiwibank {
  $navbar-inverse-bg: #89b548;
  $navbar-inverse-link-color: #fff;
  $navbar-inverse-link-active-bg: #8dc345;
  $navbar-inverse-link-transform: capitalize;
  $navbar-font-family: 'Open Sans';
  $navbar-font-weight: 600;
  $nav-link-padding: 20px;
  $navbar-font-size: 14px;
  $navbar-inverse-link-active-color: #f0f0f0;
  $link-color: #8dc345;
  $link-hover-color: #8dc345;
  $footer-bg: #3c3c3b;
  $footer-color: #fff;
  $btn-primary: #8dc345;
  $btn-primary-border: #8dc345;
  $btn-theme: #fff;
  $btn-default: #8dc345;
  $btn-success: #8dc345;
  $btn-success-border: #8dc345;
  $sub-navbar-bg: #8dc345;
  $subnav-link-color: #8dc345;
  $subnav-link-active-bg: transparent;
  $subnav-link-active-color: #fff;
  $subnav-padding: 14px 18px;
  $subnav-font-family: 'Montserrat';
  $subnav-font-size: 13px;
  $checkbox-success: #8dc345;
  $checkbox-success-border: #8dc345;
  $checkbox-primary: #8dc345;
  $checkbox-primary-border: #8dc345;
  $checkbox-warning: #8dc345;
  $checkbox-warning-border: #8dc345;
  $checkbox-danger: #e60106;
  $checkbox-danger-border: #e60106;
  @import '~assets/styles/layout';
}