.skeleton {
  h3, h4, p {
    animation: loading 1.4s infinite linear;
    background: #f6f7f8;
    background-image: -webkit-linear-gradient(to left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-image: linear-gradient(to left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
  }

  h3 {
    height: 24px;
    width: auto;
  }

  h4 {
    height: 18px;
    width: auto;
  }

  h3, h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p, span {
    height: 18px;
    width: auto;
  }
}

@keyframes loading {
  0% {
    background-position: -50rem;
  }
  100% {
    background-position: 50rem;
  }
}