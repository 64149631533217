.searchbox {
  position: relative;
  height: 45px;
  .searchbox_search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    height: 45px;
    width: 30px;
  }

  .searchbox-input {
    resize: none;
  }
  .searchbox_calendar-value {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    padding-left: 7px;
    font: normal normal normal 15px/24px 'Poppins';
    color: #2a2a2a;
    display: flex;
    align-items: center;
    background-color: white;
    font-weight: 500;
    cursor: default;
    .searchbox_remove-value {
      font-size: 20px;
      padding: 2.5px 5px 1.5px 5px;
    }
  }
  .calendar-datepicker {
    position: absolute;
    top: 50px;
    right: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    transform: translateY(-10px);
    padding: 1rem;
    background-color: white;
    box-shadow: 0 0 10px 0 #aaa;
    z-index: 1000;
    &.calendar-datepicker--visible {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
    .react-calendar {
      border: 1px solid #f4f4f4;
      border-radius: 6px;
      background-color: white;
      width: 300px;
      .react-calendar__navigation {
        background-color: #ea212d;
        button {
          color: white !important;
        }
      }
      .react-calendar__tile {
        padding: 0.8rem !important;
        &:hover {
          background-color: #ea212d;
          border-radius: 30px;
          color: white;
        }
      }
      .react-calendar__tile--active {
        background-color: #ea212d !important;
        border-radius: 30px;
        color: white !important;
        font-weight: bolder;
      }
      .react-calendar__tile--hasActive {
        background-color: white;
        color: #f3c400 !important;
      }
      .react-calendar__tile--now {
        background-color: #e3e3e3;
        border-radius: 30px;
      }
      .react-calendar__tile {
        color: #666;
      }
    }
  }
}

.searchbox_remove-value.clear {
  font-size: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background: #eeeff080 !important;
  cursor: pointer;
}

#span-2 {
  grid-column-end: span 2;
}

.searchbox.w-input {
  height: 50px;
  margin-left: 30px !important;
  border: 1px solid #ccd2d4;
  border-radius: 10px;
  display: flex;
  background: #eeeff080;

  .searchbox_search-icon {
    background: #eeeff080;
    padding: 0 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #b2b2af;
    font-size: 16px;
    position: unset;
  }

  .form-control {
    height: auto !important;
    width: 100% !important;
    border: none;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    color: #000;
    background: #eeeff080 !important;
    font-size: 16px;
    padding-left: 0;
    font-weight: normal;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #b2b2af;
    }
  }

  @media screen and (max-width: 1024px) { 
    width: 160px !important;
  }
  
  @media screen and (max-width: 991px) { 
    margin-left: 0 !important;
    width: 100% !important;
  }

  @media screen and (max-width: 479px) { 
    grid-column-end: span 1;
  }
}