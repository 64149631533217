.moniplus {
  $navbar-inverse-bg: #333333;
  $navbar-inverse-link-color: #9d9d9d;
  $navbar-inverse-link-active-bg: #393939;
  $navbar-inverse-link-transform: capitalize;
  $navbar-font-family: 'Open Sans';
  $navbar-font-weight: 600;
  $nav-link-padding: 20px;
  $navbar-font-size: 13px;
  $navbar-inverse-link-active-color: #fff;
  $link-color: #e4050f;
  $link-hover-color: #e4050f;
  $footer-bg: #f5f5f5;
  $footer-color: #333333;
  $btn-primary: #e60106;
  $btn-primary-border: #e60106;
  $btn-theme: #fff;
  $btn-default: #e60106;
  $btn-success: #e60106;
  $btn-success-border: #e60106;
  $sub-navbar-bg: #fff;
  $subnav-link-color: #333333;
  $subnav-link-active-bg: transparent;
  $subnav-link-active-color: #fff;
  $subnav-padding: 14px 18px;
  $subnav-font-family: 'Montserrat';
  $subnav-font-size: 12px;
  $checkbox-success: #e60106;
  $checkbox-success-border: #e60106;
  $checkbox-primary: #e60106;
  $checkbox-primary-border: #e60106;
  $checkbox-warning: #e60106;
  $checkbox-warning-border: #e60106;
  $checkbox-danger: #e60106;
  $checkbox-danger-border: #e60106;
  @import '~assets/styles/layout';
}