.open-status {
  border-color: #2468ae;
  background-color: #e6f0fa;
}

.confirmed-status {
  border-color: #fd689e;
  background-color: #ffedf4;
}

.partially-settled-status {
  border-color: #f8ad08;
  background-color: #fffae7;
}

.settled-status {
  border-color: #f8ad08;
  background-color: #fffae7;
}

.released-status {
  border-color: #622188;
  background-color: #eed0ff;
}

.paid-status {
  border-color: #16b08f;
  background-color: #d6fff9;
}

.completed-status {
  border-color: #0a9ba9;
  background-color: #0a9ba94d;
}

.cancelled-status {
  border-color: #ed1c24;
  background-color: #ed1c244d;
}

.pending-status {
  border-color: #2468ae;
  background-color: #e6f0fa;
}

.pending-payment-status {
  border-color: #ff7600;
  background-color: #ff76004d;
}

.refunded-status {
  border-color: #212529;
  background-color: #e91f29;
}

:is(.open-status, .paid-status, .cancelled-status, .completed-status, .partially-settled-status, .pending-payment-status, .released-status, .pending-status, .confirmed-status, .confirming-status, .settled-status, .settling-status, .refunded-status, .default-status) {
  display: flex;
  height: 30px;
  min-width: 112px;
  padding: 5px 15px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
  color: #2a2a2a;
  font-size: 14px;
}